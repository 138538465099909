import { appConfig } from "../config";
import * as moment from 'moment-timezone';
//const moment = require('moment-timezone');

const {timezone} = appConfig;
const SECOND = 1000;
const MINUTE = 1000 * 60;
const HOUR = 1000 * 60 * 60;
//const DAY = 1000 * 60 * 60 * 24;
//const MONTH = 1000 * 60 * 60 * 24 * 30;
//const YEAR = 1000 * 60 * 60 * 24 * 30 * 12;

class Timer {
  $_data = {}
  counter = 0

  init = (callTime) => {
    this.$_data.callTime = Number(callTime); //minisecond
    const nowTime = this.time();
    var diff = nowTime - callTime;
    
    console.log('Timer', callTime, nowTime, diff, this.date(Number(callTime), 'YYYY-MM-DD HH:mm:ss'), this.date(Number(nowTime), 'YYYY-MM-DD HH:mm:ss'));

    this._calcTime();
    this._startTimer();
  }

  time = () => {
    return moment().tz(timezone).valueOf(); //.format('x');valueOf();
  }
  
  now = (format='YYYY-MM-DD HH:mm:ss') => {
    return moment().tz(timezone).format(format);
  }

  date = (time, format='YYYY-MM-DD') => {
    return moment(time).tz(timezone).format(format);
  }

  _calcTime = () => {
    const {callTime} = this.$_data;
    const nowTime = this.time();
    var diff = nowTime - callTime;
    // strip the ms
    //diff /= 1000;
    //console.log('Timer', callTime, nowTime, diff, this.date(Number(callTime), 'YYYY-MM-DD HH:mm:ss'), this.date(Number(nowTime), 'YYYY-MM-DD HH:mm:ss'));

    //var msec = diff;
    // get seconds
    var secs = Math.floor((diff/SECOND)%60)
        , mm = Math.floor((diff/MINUTE)%60)
        , hh = Math.floor((diff/HOUR)%24);
        //, days  = parseInt(diff/(1000*60*60*24));

    //var secs = Math.round(diff);
    //var mm = Math.round(secs / 60);
    //var hh = Math.round(mm / 60);

    //var hh = Math.floor(msec / 1000 / 60 / 60);
    //msec -= hh * 1000 * 60 * 60;
    //var mm = Math.floor(msec / 1000 / 60);
    //msec -= mm * 1000 * 60;
    //var ss = Math.floor(msec / 1000);
    //msec -= ss * 1000;

    this.$_data.hh = hh;
    this.$_data.mm = mm;
    this.$_data.ss = secs;

    this.displayTimer();
  }

  _incrementTime = () => {
    let hh = this.$_data.hh;
    let mm = this.$_data.mm;
    let ss = this.$_data.ss;

    ss++;
    if (ss >= 60) {
      mm++;
      ss = 0;
    }

    if (mm >= 60) {
      hh++;
      mm = 0;
    }

    this.$_data.hh = hh;
    this.$_data.mm = mm;
    this.$_data.ss = ss;

    this.displayTimer();
  }

  _startTimer = () => {
    this.$_data.interval = setInterval(() => {
      this._calcTime()
    }, 1000);
  }

  stopTimer = () => {
    if (this.$_data.interval) {
      clearInterval(this.$_data.interval);
    }
  }

  displayTimer = () => {
    const $timer = document.getElementById("timer-id");
    let hh = this.$_data.hh.toString();
    let mm = this.$_data.mm.toString();
    let ss = this.$_data.ss.toString();

    //console.log('displayTimer', $timer, this.$_data, $timer.querySelector('.timer'))
    var strTime = '';

    if (this.$_data.hh < 0 || this.$_data.mm < 0 || this.$_data.ss < 0) {
      return;
    }
    if (this.$_data.hh) {
      strTime += hh.padStart(2, '0') + ':'
    }
    strTime += mm.padStart(2, '0') + ':'
    strTime += ss.padStart(2, '0');
    if ($timer) {
      $timer.querySelector('.timer').innerHTML = strTime;
    }
  };
}

// create instance
const timer = new Timer();
// lock instance
Object.freeze(timer);

export default timer;
