/* eslint-disable */ 
/*global RioInterface */
/*eslint-disable no-unused-vars, no-var, max-len */
/*eslint no-undef: "error"*/
import { credentials } from "../config";
import RioMeetJS from "../components/rio-meet/riomeetjs";
import store from "../store";
import CallService from '../services/call-service';
import { setVideoInput } from "../actions/videoInput";
import {setAudioInputs} from "../actions/audioInput";
import { RioHelper, RioAppClient, getAvailableDevices} from "../components/appClient/rioappclient";
import { RioLogger} from "../components/rio-meet/riomeetjs";
import { appData, users} from "../config";
import Helper from '../helpers/helpers';
import {defaultSetting} from "../config";
import {CALLING, SETTING, WAITING} from "../helpers/constants";
import {fetchUsers} from "../actions/users";
import {setSession} from "../actions/session";
import {setAudioOutputs} from "../actions/audioOutput";

import {
    setMicDeviceId
} from "../actions/setting";

class UIService {

    init = () => {
        RioAppClient.instance();
        CallService.initStoreSetting(RioAppClient.getSetting(defaultSetting));
        
        if (CallService.isScreen(SETTING)) {
            UI.initSetting();
            RioMeetJS.videoroom.onAudioInputChanged = this.onAudioInputChanged.bind(this);
        }
        if (CallService.isScreen(CALLING) || CallService.isScreen(WAITING)) {
            UI.initCalling();
        }
        
        RioAppClient.on('onEnterPIP', this._onEnterPIP);
        RioAppClient.on('onLeavePIP', this._onLeavePIP);
        
        //demo get param
         const webview = Helper.getParam('webview-web');
         if (webview == 11) {
            const json = {
                creds: credentials,
                options: appData,
                users: users
            }
            if (CallService.isScreen(SETTING)) {
                const _setting = '{"agentType":"android","audioMuted":false,"cameraDeviceId":"user","currentUserId":"007","isFilter":false,"language":"ja-JP","micDeviceId":"default","videoMuted":false,"voice":"2"}';
                RioAppClient.setting(_setting);
            } else {
                RioAppClient.init(JSON.stringify(json));
            }
        }

        this._initDeviceList();
    }
    initCalling = () => {
        RioMeetJS.videoroom.onAppInit = this.onAppInit.bind(this);
        RioMeetJS.videoroom.onDeviceListChanged = this.onDeviceListChanged.bind(this);
    };
    
    initSetting = () => {
        RioAppClient.on('onAppSetting', this.onAppSetting);
        RioMeetJS.videoroom.onDeviceListChanged = this.onDeviceListChanged.bind(this);
    };
    
    onAppInit = async (users) => {
      const session = RioMeetJS.createRioApp();
      store.dispatch(setSession(session));
      CallService.init();
      CallService.initStoreSetting(RioAppClient.getSetting({}));
      const devices = await this._initDeviceList();
      RioLogger.info('debug : onAppInit devices', JSON.stringify(devices));

      setTimeout(() => {
        this._renderDevices(devices).then(() => {
            store.dispatch(setAudioInputs([]));
            store.dispatch(fetchUsers(users));
            if (CallService.isScreen(CALLING) && Helper.getParam('webview-web') == 11) {
              CallService.join();
            }
        });
      }, 0);
      if (CallService.isScreen(CALLING) && Helper.getParam('webview-web') == 11) {
        setTimeout(() => {
            const json1 = '[{"deviceId":"484FA2F7DA7A37934D26F30A4D8D4B61FDF4FC5F","label":"headset microphone","current":"0"},{"deviceId":"00:00:00:00:08:05-tsco","label":"iPhone microphone","current":"1"}]';
            RioAppClient.onAudioInputChanged(json1);
        }, 6000);
      }
    }
    
    onAppSetting = async (setting) => {
        CallService.initStoreSetting(setting);
        const devices = await this._initDeviceList();

        RioLogger.info('debug : _onAppSetting', JSON.stringify(setting), JSON.stringify(devices));
        setTimeout(() => {
          this._renderDevices(devices).then(() => {
              if (CallService.isScreen(SETTING)) {
                store.dispatch(setAudioInputs([]));
                CallService.initSettingInSettingScreen()
              }
          });
        }, 0);
    }

    /**
     * callback when application trigger
     *
     * @param {Object} data 
     * @returns {void} 
     */
    onAudioInputChanged = (devices) => {
        const found = devices.filter(device => (device.current == '1' || device.current == 'true'));
        const currentDevice = found.length ? found.shift() : false;
        RioLogger.info('[debug] : onAudioInputChanged', devices, currentDevice);

        store.dispatch(setAudioInputs(devices));
        if(currentDevice && currentDevice?.deviceId) {
            store.dispatch(setMicDeviceId(currentDevice.deviceId))
        }
    };

    renderCamera = async (avaiDevices) => {
         if (RioHelper.isMobile()) {
          const devices = [
            {
                "deviceId": RioMeetJS.constants.CameraFacingMode.USER,
                "kind": "videoinput",
                "label": "facing front",
            },
            {
                "deviceId": RioMeetJS.constants.CameraFacingMode.ENVIRONMENT,
                "kind": "videoinput",
                "label": "facing back",
            }
          ];
          store.dispatch(setVideoInput(devices));
        } else {
          const videoDevices = avaiDevices.filter(device => device.kind === 'videoinput');
          RioLogger.info('[debug] renderCamera', videoDevices);
          store.dispatch(setVideoInput(videoDevices));
        }
    };

    renderAudio = async (avaiDevices) => {
        const audioDevices = Helper.getAudioInput(avaiDevices);
        store.dispatch(setAudioInputs(audioDevices));
    };
    
    renderSpeaker = async (avaiDevices) => {
        const speakerDevices = Helper.getAudioOutput(avaiDevices);
        store.dispatch(setAudioOutputs(speakerDevices));
    };

    _renderDevices = async (devices) => {
        RioLogger.info('[debug] _renderDevices', JSON.stringify(devices));
        return new Promise(resolve => {
          this.renderCamera(devices);
          //this.renderAudio(devices);
          this.renderSpeaker(devices)
          resolve();
        });
    };
  
    _initDeviceList = () => {
      const { mediaDevices } = RioMeetJS;

      if (mediaDevices.isDeviceListAvailable()
              && mediaDevices.isDeviceChangeAvailable()) {
          
          return new Promise(resolve => {
              getAvailableDevices()
              .then(devices => {
                  // Ugly way to synchronize real device IDs with local
                  // storage and settings menu. This is a workaround until
                  // getConstraints() method will be implemented in browsers.
                  resolve(devices);
              });
          });
      }

      return Promise.resolve([]);
  }

  /**
     * Event listener for JitsiMediaDevicesEvents.DEVICE_LIST_CHANGED to
     * handle change of available media devices.
     * @private
     * @param {MediaDeviceInfo[]} devices
     * @returns {Promise}
  */
  onDeviceListChanged(devices) {
      RioLogger.info('debug : onDeviceListChanged', devices, JSON.stringify(devices));
      this._renderDevices(devices);
  };

  _onEnterPIP = () => {
    RioLogger.info('onEnterPIP');
  }

  _onLeavePIP = () => {
    RioLogger.info('onLeavePIP');
  }
}

// create instance
const UI = new UIService();
// lock instance
Object.freeze(UI);

export default UI;
