/* eslint-disable */ 
import React, {Component} from 'react'
import { connect } from "react-redux";
import CallService from "../../../services/call-service";
import { TYPE_AUDIO } from "../../../helpers/constants";
import { RioLogger } from "../../rio-meet/riomeetjs";
class AudiosSetting extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    componentDidMount() {
        const {audios, setting} = this.props;
        const {micDeviceId} = setting;

        if (audios.length && micDeviceId === undefined) {
            const defaultAudioDevice = audios[0];
            CallService.setDeviceIdForSetting(defaultAudioDevice.deviceId, TYPE_AUDIO);
        }
    }

    async onchangeAudioDevice(event) {
        const audioDeviceId =  event.target.value
        CallService.setAudioDeviceIdUsing(audioDeviceId);
    }

    render() {
        const {audios, setting, isAudioDisable} = this.props;
        const {micDeviceId} = setting;
        const found = audios.filter(device => (device.current == '1' || device.current == 'true'));
        const currentDevice = found.length ? found.shift() : false;
        let currentDeviceId = micDeviceId;
        if(currentDevice && currentDevice?.deviceId) {
            currentDeviceId = currentDevice?.deviceId;
        }

        RioLogger.info(`[debug]: AudiosSetting setMicDeviceId ${micDeviceId} ${currentDeviceId}`, JSON.stringify(setting), audios);

        return (
            <div className="line_form">
                <label>オーディオ</label>
                {audios &&
                    <select disabled={isAudioDisable} className="form_setting"
                       onChange={(e) => this.onchangeAudioDevice(e)}
                       defaultValue={currentDeviceId}
                       value = { currentDeviceId }
                    >
                    {audios.map((item, index) => (
                        <option key={index} value={item.deviceId}>{item.label || 'Speakerphone'}</option>
                    ))}
                </select>
                }
            </div>
        )
    }
}

const mapStateToProps = state => ({
    audios: state.audioInput && state.audioInput.audios,
    setting: state.setting
});

export default connect(mapStateToProps)(AudiosSetting);
