export const messages = {
  login:
      "Login as any user on this computer and another user on another computer.",
  create_session: "Creating a session...",
  connect: "Connecting...",
  connect_error:
      "Something went wrong with the connection. Check internet connection or user info and try again.",
  login_as: "Logged in as ",
  title_login: "Choose a user to login with:",
  title_callee: "Choose users to call:",
  calling: "Calling...",
  webrtc_not_avaible: "WebRTC is not available in your browser",
  no_internet: "Please check your Internet connection and try again"
};

export const credentials = {
    secret_id: process.env.REACT_APP_SECRET_ID,
    secret_key: process.env.REACT_APP_SECRET_KEY

};

export const appConfig = {
  debug: { mode: 1 },
  timezone: "Asia/Tokyo",
  disableAudio: true
};

// export const appIdFilter = "839ca2b0b6c708bf018ff6c2ef92b320b5c4d4cd2a6e4dac184f84cd90216da062ca9d63ade26479";
export const appIdFilter = process.env.REACT_APP_APP_ID_FILTER;


export const appData = {
  "token": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOi8vYXBpLnJpby12aWRlby1jYWxsLmNsaWNrL3NlcnZlci9hcGkvdjEvc2RrL3Nlc3Npb24iLCJpYXQiOjE2NzU5OTg2MTMsIm5iZiI6MTY3NTk5ODYxMywianRpIjoiZXpwdlc5MXg1anNXaGNmcCIsInN1YiI6IjEiLCJwcnYiOiJhOTUyZjViNzZmNTIzMGI3YjUzMDc3MDlkMWE5NGQ5ODQ1OTk1NTkwIiwiYXBwX2lkIjoxLCJzZWNyZXRfaWQiOiJUWjhKZWplNzg3RnQ5MmVhNmsiLCJzZWNyZXRfa2V5IjoiWjY2bFJnRktjaGlxcTNjSEdoIn0.zzm72-fW6jlXNr2NR5LxZLxVzujEQEKRfSj9YmGHQRs",
  "app": {
    "app_id": "081c6cfddf669a5749001e35397bbfba",
    "app_name": "App GMO 1 Z.com",
    "autoRecord": false,
    "default_video_resolution": 720,
    "default_video_bitrates": {
      "low": 100000,
      "standard": 600000,
      "high": 2000000
    },
    "resolutions": {
      "high": {
        "resolution": 1080,
        "audioQuality": {
          "stereo": true,
          "opusMaxAverageBitrate": 510000
        }
      },
      "medium": {
          "resolution": 480,
        "audioQuality": {
              "stereo": false,
              "opusMaxAverageBitrate": 240000
        }
      },
      "low": {
          "resolution": 180,
        "audioQuality": {
              "stereo": false,
              "opusMaxAverageBitrate": 6000
        }
      }
    }
  }
};

export const users = [
    {
        "id": 103,
        "user_id": "test26",
        "name": "test26",
    },
    {
        "id": 104,
        "user_id": "test26_1",
        "name": "test26_1",
    },
    {
        "id": 105,
        "user_id": "test28",
        "name": "test28",
    },
    {
        "id": 106,
        "user_id": "test28_1",
        "name": "test28_1",
    },
    {
        "id": 107,
        "user_id": "register1",
        "name": "register1",
    },
    {
        "id": 108,
        "user_id": "register2",
        "name": "register2",
    },
    {
        "user_id": "test_12a@gmail.com",
        "name": "test_12a@gmail.com",
    },
    {
        "user_id": "test_123a@gmail.com",
        "name": "test_123a@gmail.com",
    }
];

export const callOptions = {
  "language": "vi-VN",
  "startTime": new Date().getTime(),
  "callType": "1",
  "acceptCallType": "1",
  "appId": "081c6cfddf669a5749001e35397bbfba",
  "fromAgentType": "web",
  "toAgentType": "web",
  "roomName": 'gmotest-001',
};

export const defaultSetting = {
  userId: '',
  language: 'ja-JP',
  cameraDeviceId: 'user',
  micDeviceId: 'default',
  voice: "none",
  isFilter: false,
  audioMuted: false,
  videoMuted: false,
  speaker: true,
};
