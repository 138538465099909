import store from "../store";
import {appIdFilter} from "../config";
import baseBeauty from "../assets/effects/beauty";
import {isFiltered, setEnableFilter, setInProcess} from "../actions/filter";
import { RioLogger } from "../components/rio-meet/riomeetjs";

//import * as DeepAR from 'deepar';
//import deeparWasmPath from 'deepar/wasm/deepar.wasm';
//import faceTrackingModelPath from 'deepar/models/face/models-68-extreme.bin';
//import segmentationModelPath from 'deepar/models/segmentation/segmentation-160x160-opt.bin';
//import poseEstimationWasmPath from 'deepar/wasm/libxzimgPoseEstimation.wasm';
//import footDetectorPath from 'deepar/models/foot/foot-detection-96x96x6.bin';
//import footTrackerPath from 'deepar/models/foot/foot-tracker-96x96x18-test.bin';
//import footObjPath from 'deepar/models/foot/foot-model.obj';
import {TYPE_VIDEO} from "../helpers/constants";
import CallService from "./call-service";
import RioMeetJS from "../components/rio-meet/riomeetjs";
import { RioHelper } from "../components/rio-meet/riomeetjs";
import {setIsFilter} from "../actions/setting";

class FilterFace {
    deepAR = '';
    _options={};

    //init = async (params = {}) => {
    //    const options = this._getOptions(params);
    //    const canvas = document.getElementById(options.idCanvas);
    //    const video = document.getElementById(options.idLocalStream);
        
    //    const constraints = this._obtainConstraints(options, video);
    //    const { facingMode } = constraints;

    //    const cameraConfig = Object.assign({}, {
    //        disableDefaultCamera: true,
    //        //facingMode: facingMode
    //    });
    //    console.log('init deepAR', options, constraints, JSON.stringify(cameraConfig));

    //    if (!this.deepAR) {
    //        this.deepAR = await DeepAR.initialize({
    //            licenseKey: appIdFilter, 
    //            canvas: canvas,
    //            rootPath: "./deepar-resources", // See webpack.config.js and package.json build script.
    //            additionalOptions: {
    //                // Disable the default webcam preview.
    //                cameraConfig: cameraConfig,
    //                //hint: "faceModelsPredownload", // Download the face tracking model as soon as possible.
    //                //faceTrackingConfig: {
    //                //    modelPath: faceTrackingModelPath
    //                //},
    //                segmentationConfig: {
    //                    modelPath: segmentationModelPath
    //                },
    //                footTrackingConfig: {
    //                    poseEstimationWasmPath,
    //                    detectorPath: footDetectorPath,
    //                    trackerPath: footTrackerPath,
    //                    objPath: footObjPath,
    //                    //tfjsBackendWasmPath: "path/to/deepar/wasm/tfjs-backend-wasm.wasm",
    //                    //tfjsBackendWasmSimdPath: "path/to/deepar/wasm/tfjs-backend-wasm-simd.wasm",
    //                    //tfjsBackendWasmThreadedSimdPath: "path/to/deepar/wasm/tfjs-backend-wasm-threaded-simd.wasm",
    //                },
    //                deeparWasmPath: deeparWasmPath
    //            }
    //        });
    //        this.deepAR.callbacks.onFaceVisibilityChanged = (visible) => {
    //            console.log('onFaceVisibilityChanged', visible);
    //        };
    //        //this.deepAR.callbacks.onFaceTracked = (faceDataArray) => {
    //        //    console.log('onFaceTracked', faceDataArray);
    //        //};
    //        this.deepAR.callbacks.onFeetTracked = () => {
    //            console.log('onFeetTracked');
    //        };
    //        this.deepAR.callbacks.onFootTrackingInitialized = () => {
    //            console.log('onFootTrackingInitialized');
    //        };
    //        this.deepAR.callbacks.onSegmentationInitialized = () => {
    //            console.log('onSegmentationInitialized');
    //        };
    //    }
    //}

    setFilter = async (params = {}) => {
        const options = this._getOptions(params);
        const canvas = document.getElementById(options.idCanvas);
        const video = document.getElementById(options.idLocalStream);
        if (!video) {
            store.dispatch(setInProcess(false));
            return false;
        }

        try {
            if (!this.deepAR) {
                const constraints = this._obtainConstraints(options, video);
                const { facingMode, width, height } = constraints;
                const videoConstraints = constraints?.video || {}

                RioLogger.log('setFilter this.deepAR---', options, constraints)

                let stream = store.getState().localStream.localTracks;
                this.deepAR = window.DeepAR({
                    canvasWidth: width,
                    canvasHeight: height,
                    licenseKey: appIdFilter,
                    canvas: canvas,
                    numberOfFaces: 1,
                    libPath: './',
                    emotions: true,
                    segmentationInfoZip: 'segmentation.zip',
                    onInitialize: () => {
                        const {audioInput, setting} = store.getState()
                        // start video immediately after the initialization, mirror = true
                        const params = {
                            width: videoConstraints?.width || width,
                            height: videoConstraints?.height || height,
                            facingMode: facingMode,
                            deviceId: setting.cameraDeviceId,
                        }
                        console.log('params---', params)
                        if (!this.deepAR) {
                            return;
                        }

                        if (typeof this.deepAR.startVideo === 'function') {
                            RioLogger.log('[debug] : startVideo', params)
                            this.deepAR.startVideo(false, params);
                        }
                        if (options.isSetting) {
                            this.deepAR.setVideoElement(video);
                        }
                        this.deepAR.switchEffect(0, 'slot', baseBeauty, () => {
                            // effect loaded
                        });
                    },
                    onVideoStarted: () => {
                        // tell the DeepAR SDK about our new video element
                        if (stream) {
                            // Get the stream
                            const canvasStream = canvas.captureStream(25); // 25 FPS
                            const paramsEffect = {
                                enabled: true,
                                // blurValue: 8,
                                backgroundType: TYPE_VIDEO,
                                url: canvasStream,
                                canvasId: options.idCanvas,
                                mirror: true
                            };
                            RioLogger.log('paramsEffect', paramsEffect)
                            this.toggleBackgroundEffect(paramsEffect);
                        }
                        store.dispatch(isFiltered(true))
                        store.dispatch(setInProcess(false));
                        this.setIsFilterForSetting(true);
                    },
                    onError: (err) => {
                        RioLogger.log(`Error onError: ${err?.message}`, options)
                        this.setOffFilter();
                    }
                });
                this.deepAR.downloadFaceTrackingModel(`./models-68-extreme.bin`);
            }
        } catch (err) {
            //end try
            RioLogger.log(`setFilter error: ${err?.message}`);
            this.setOffFilter();
        }
    }

    startCamera = async (options, videoEle, canvasEle) => {
        const constraints = this._obtainConstraints(options, videoEle);
        const {video} = constraints || {};

        const cameraOptions = {
            mirror: false,
            mediaStreamConstraints: video, //MediaStreamConstraints;
            cameraPermissionAsked: (() => { RioLogger.log('callback cameraPermissionAsked');}),
            cameraPermissionGranted: (() => { RioLogger.log('callback cameraPermissionGranted');}),
            callback5: (() => { RioLogger.log('function 5');}),
            callback6: (() => { RioLogger.log('function 6');}),
        };

        setTimeout(() => {
            canvasEle.width = constraints.width;
            canvasEle.height = constraints.height;
        }, 0);
        RioLogger.log(`[debug] startCamera`, cameraOptions, constraints);

        this.deepAR.setPaused(false);
        await this.deepAR.startCamera(cameraOptions);
    }

    stopFilter = async() => {
        RioLogger.log(`[debug] stopFilter`);
        const {isSetting} = this._options;

        if (isSetting === false) {
            await this.toggleBackgroundEffect({enabled: false});
        }
        return new Promise(resolve => {
            setTimeout(() => {
                this.stopVideoFilter();
                return resolve(true);
            }, 1000);
        });
    }

    setOffFilter = () => {
        RioLogger.log(`[debug] setOffFilter`, this._options);
        const {isSetting} = this._options;

        this.setIsFilterForSetting(false);
        if (isSetting === false) {
            this.toggleBackgroundEffect({enabled: false});
        }
        this.destroyFilter();
        store.dispatch(setInProcess(false));
    }

    stopVideoFilter() {
        try {
            if (this.deepAR) {
                RioLogger.log(`[debug] stopVideoFilter`);
                //this.deepAR.setPaused(true);
                this.deepAR.clearEffect();
                this.deepAR.stopVideo();
                //this.deepAR.stopCamera();
                this.deepAR.shutdown();
                delete this.deepAR;
            }
        } catch (err) {
            RioLogger.log(`stopVideoFilter error: ${err?.message}`);
        }
    }

    destroyFilter() {
        RioLogger.log(`[debug] destroyFilter`);
        this.stopVideoFilter();
        if (this.deepAR) {
            this.deepAR.shutdown();
            delete this.deepAR;
        }

        store.dispatch(setEnableFilter(false));
        store.dispatch(isFiltered(false));
    }

    setIsFilterForSetting(isFilter) {
        store.dispatch(setIsFilter(isFilter))
    }

    toggleBackgroundEffect = async (options) => {
        let tracks = store.getState().localStream.localTracks;
        let session = CallService._getSession();
        if (CallService.checkExistVideoOrAudioTrack(tracks) && session) {
            await session.toggleBackgroundEffect(tracks, options);
        }
        return Promise.resolve();
    }

    _getOptions = (options) => {
        const defaultParam = {
            isGetSize: true,
            isSetting: false,
            timeout : 1000,
        };
        const defaultOptions = {...defaultParam,...options};
        const { isSetting } = defaultOptions;

        const canvasParam = isSetting ? {
            idCanvas: 'canvasPreview',
            idLocalStream: 'localVideoPreview',
        } : {
            idCanvas: 'deeparCanvas',
            idLocalStream: 'localStream',
        };

        const params = {...defaultParam, ...canvasParam, ...options};
        const { _options } = {...this};

        this._options = {..._options, ...params};
        RioLogger.log(`[debug] _getOptions`, this._options);
        return params;
    }
    
    _obtainConstraints = (options, videoEle) => {
        let facingMode = undefined;
        const { setting } = store.getState();
        const preCall = store.getState().PreCall;
        const defaultOptions = {width: 1280, height: 720};
        const mediaOptions = {width: 0, height: 0};

        RioLogger.log(`[debug] _obtainConstraints`, options, videoEle?.videoWidth, videoEle?.videoHeight);
        if ( ((preCall && preCall.isShowPreview) || options.isGetSize) && videoEle?.videoWidth > 0) {
            mediaOptions.width = videoEle.videoWidth;
            mediaOptions.height = videoEle.videoHeight;
        }
        const isMobile = RioHelper.isMobile();

        if (RioHelper.isMobile()) {
            facingMode = (setting.cameraDeviceId === RioMeetJS.constants.CameraFacingMode.ENVIRONMENT) 
                ? RioMeetJS.constants.CameraFacingMode.ENVIRONMENT 
                : RioMeetJS.constants.CameraFacingMode.USER;
        }

        // When you want to ask the camera permission and start the camera.
        const params = {
            video: true,
            facingMode: facingMode,
            mirror: false,
            echoCancellation: false,
        };
        
        const constraints = RioMeetJS.obtainConstraints(params);
        const {video} = constraints || {};
        let width, height, w, h, ideal = false;
        width = video?.width;
        height = video?.height;

        if (isNaN(video.width) && video.width?.ideal) {
            w = video.width?.ideal;
            h = video.height?.ideal;
            ideal = true;
        } else {
            w = video.width;
            h = video.height;
        }
        constraints.facingMode = facingMode;

        if (isMobile && parseInt(w, 10) > parseInt(h, 10)) {
            let temp1 = h, temp2;
            h = w;
            w = temp1;
            
            if (ideal) {
                temp2 = height;
                height = width;
                width = temp2;

                constraints.video.width = width;
                constraints.video.height = height;
            }
        }
        constraints.width = w;
        constraints.height = h;
        RioLogger.log(`[debug] obtainConstraints`, w, h, constraints);

        return constraints;
    }
}

export default new FilterFace()
