import {
  SET_AUDIO_INPUT,
  SET_AUDIO_DEVICE
} from '../actions/audioInput'

export default (state = null, action) => {
  switch (action.type) {
    case SET_AUDIO_INPUT:
      const {audios} = action
      return {...state, audios}
    case SET_AUDIO_DEVICE:
      const {localAudioTrack} = action;
      return {...state, ...localAudioTrack}
    default:
      return state
  }
}