import React, {Component} from 'react'
import {connect} from "react-redux";
import CallService from "../../services/call-service";
class Speaker extends Component {
    onclickSpeaker =  (event) => {
        CallService.setSpeakerForSetting(event.target.checked)
    }

   isSpeaker = () => {
        const {setting} = this.props;
        return setting && setting.speaker;
    }

    render() {
        const isSpeaker = this.isSpeaker();
        return (
            <div className="line_form">
                <label>スピーカー</label>
                <label className="switch">
                    <input type="checkbox"
                           onChange={this.onclickSpeaker}
                           checked= {isSpeaker}
                    />
                        <span className="slider round"/>
                </label>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        setting : state.setting
    };
}

export default connect(mapStateToProps)(Speaker);

