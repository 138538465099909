import React, {Component} from 'react'
import CallService from "../../services/call-service";
import {connect} from "react-redux";
class PIPMobile extends Component {

    onclickPip =  (event) => {
         CallService.togglePIP(event.target.checked)
    }

    render() {
    const {pip} = this.props
    const isDisable = pip.isDisable == undefined || pip.isDisable;
    const btnClass = isDisable ? 'switch disabled' : 'switch';

        return (
            <div className="line_form">
                <label>PiP</label>
                <label className={btnClass} >
                    <input type="checkbox"
                          onChange={this.onclickPip}
                          checked={!!(pip && pip.enable)}
                          disabled={isDisable}
                    />
                    <span className="slider round"/>
                </label>
            </div>
        )
    }
}

function mapStateToProps(state) {
   return {
      pip : state.pip
   }
}

export default connect(mapStateToProps)(PIPMobile);
