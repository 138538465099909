import {
    SET_LOCAL_STREAM,
} from '../actions/localStream'

export default (state = {}, action) => {
    switch (action.type) {
        case SET_LOCAL_STREAM:
            const {localTracks} = action;
            return {...state, localTracks};
        default:
            return state
    }
}
