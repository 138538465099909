/* eslint-disable */ 
import React, {Component} from 'react';
import {connect} from "react-redux";
import CallService from "../../services/call-service";

/**
 * React component for displaying a audio level meter for a JitsiLocalTrack.
 */
class RecordPreview extends Component {
    /**
     * Initializes a new RecordPreview instance.
     *
     * @param {Object} props - The read-only React Component props with which
     * the new instance is to be initialized.
     */
    constructor(props) {
        super(props);
        this.state = {
            isInProcess : false
        };
        this.start = this.start.bind(this)
    }
    
    TIME_RECORD = 5;
    downloadTimer
    ID_COUNT_MIC = 'count-check-micro'
    LABEL = 'Play a test sound'
    
    start = () => {
        const {props} = this
        
        if (props.isDisable){return false;}
        
        const {audioDeviceId} = props.audioInput;
        const {value} = props.voiceChange;
        CallService.checkOutputAudio(audioDeviceId, value, this.TIME_RECORD)
       
        this.setState({isInProcess: true})
        this.countDownRecord();
    };

    countDownRecord = () => {
        const self = this;
        let timeLeft = self.TIME_RECORD - 1;
        clearInterval(this.downloadTimer);
        this.downloadTimer = setInterval(function () {
            if (timeLeft <= 0) {
                self.setState({isInProcess: false})
                clearInterval(self.downloadTimer);
                return;
            }
            document.getElementById(self.ID_COUNT_MIC).value = timeLeft;
            timeLeft -= 1;
        }, 1000);
    }

    render() {
        return (
            <section>
            <div className = 'd-flex align-items-center mt-3 mb-3'>
                <label className="label-preview w-25">音声テスト</label>
                {
                    !this.state.isInProcess && 
                    <a
                        aria-label = {this.LABEL}
                        style={{paddingBottom:"5px"}}
                        onClick = { this.start }
                        role = 'button'
                        tabIndex = { 0 }>
                      {this.LABEL}
                    </a>
                }
                {
                    this.state.isInProcess && 
                    <progress 
                        className="w-100p" 
                        value={this.TIME_RECORD} 
                        max={this.TIME_RECORD} 
                        id={this.ID_COUNT_MIC}
                        >
                    </progress>
                }
            </div>
            </section>
        );
    }
}

function mapStateToProps(state) {
    const {audioInput} = state;
    const {voiceChange} = state;
    return {
        audioInput,
        voiceChange
    }
}

export default connect(mapStateToProps)(RecordPreview);
