import {
  SET_VISIBLE,
  RESET_MODAL_ICOMING
} from '../actions/modalIcoming'

export default (modalIncoming = null, action) => {
  switch (action.type) {
    case SET_VISIBLE:
      const newObj = {
        visible: action.visible,
        name: action.name
      }
      return newObj

    case RESET_MODAL_ICOMING:
      return null

    default:
      return modalIncoming
  }
}