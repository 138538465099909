export const SET_ENABLE_FILTER = 'SET_ENABLE_FILTER'
export const IS_FILTERED = 'IS_FILTERED'
export const SET_REMOTE_ENABLE_FILTER = 'SET_REMOTE_ENABLE_FILTER'
export const RESET_FILTER = 'RESET_FILTER'
export const SET_IN_PROCESS= 'SET_IN_PROCESS'

export const setEnableFilter = (value) => ({ type: SET_ENABLE_FILTER,  value})
export const isFiltered = (isFiltered) => ({ type: IS_FILTERED,  isFiltered})
export const setRemoteEnableFilter = (value) => ({ type: SET_REMOTE_ENABLE_FILTER,  value})
export const resetFilter = () => ({ type: RESET_FILTER })

export const setInProcess = (inProcess) => ({ type: SET_IN_PROCESS,  inProcess})
