/* eslint-disable */ 
import React, {Component} from 'react'
import {connect} from "react-redux";
import RioMeetJS from "../rio-meet/riomeetjs";
class RemoteVideo extends Component {
    constructor(props) {
        super(props)
        this.state = {
            classRemoteStream : ''
        }
    }
    componentDidUpdate(prevProps, prevState) {
        const remoteStream = this.props.remoteStream
        if (prevProps.remoteStream !== remoteStream) {
            const isVisibleRemote = remoteStream.isVisible ? 'hidden' : 'visible';
            this.setState({
            styleRemote : {visibility: isVisibleRemote},
            classRemoteStream : remoteStream.width < 90 ? '' : ''
            })
        }
    }
      
    getClassRemoteStream = () => {
        const {settingInCalling} = this.props;
        const remoteOptions = settingInCalling && settingInCalling.remoteOptions;
        const {videoMuted, deviceId} = remoteOptions || {};
        let remoteClassStr = "videochat-remote-stream";
        if (videoMuted == true) {
            remoteClassStr += " d-hidden";
        }

        return deviceId != RioMeetJS.constants.CameraFacingMode.ENVIRONMENT 
        ? `off-minor ${remoteClassStr}` : `${remoteClassStr}`;
    }
    
    render() {
        const classRemote = this.getClassRemoteStream();
        return (
            <div className="video_full">
                <div className="video_container">
                    <video playsInline id="remoteStream"
                        autoPlay
                        width="100%" height="100%"
                        className={classRemote}
                    />
                    <img 
                        data-html2canvas-ignore="true"
                        id="remoteStream-img"
                        className="html2canvas-ignore avatar-remote" 
                        width="100%" 
                        alt="" 
                        src={require('../../assets/images/avatar.png')}
                    />
                </div>
                <div style={{visibility: "hidden"}} id="sub-title" className="sub-title"></div>
            </div>
        )
    }
}
function mapStateToProps(state) {
    return {
        remoteStream: state.remoteStream,
        settingInCalling: state.settingInCalling,
    };
}
export default connect(mapStateToProps)(RemoteVideo);
