import {
  IS_FILTER,
  IS_MUTE_CAMERA,
  IS_MUTE_AUDIO,
  SET_TYPE_VOICE_CHANGE,
  SET_CAMERA_DEVICE_ID,
  SET_MIC_DEVICE_ID,
  SET_INIT_CONFIG, 
  SET_SPEAKER_DEVICE_ID,
} from '../actions/setting'

export default (state = null, action) => {
  switch (action.type) {
    case IS_FILTER:
      const {isFilter} = action
      return {...state, isFilter}
    case IS_MUTE_CAMERA:
      const {videoMuted} = action
      return {...state, videoMuted}
    case IS_MUTE_AUDIO:
      const {audioMuted} = action
      return {...state, audioMuted}
    case SET_TYPE_VOICE_CHANGE:
      const {voice} = action
      return {...state, voice}
    case SET_CAMERA_DEVICE_ID:
      const {cameraDeviceId} = action
      return {...state, cameraDeviceId}
    case SET_MIC_DEVICE_ID:
      const {micDeviceId} = action
      return {...state, micDeviceId} 
    case SET_SPEAKER_DEVICE_ID:
      const {speaker} = action
      return {...state, speaker}
    case SET_INIT_CONFIG:
      const {setting} = action
      return {...state, ...setting}
    default:
      return state
  }
}
