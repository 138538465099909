export const AUDIO_CALL_FIRST_TIME = 'AUDIO_CALL_FIRST_TIME'
export const IS_CALLING_STARTED = 'IS_CALLING_STARTED'
export const IS_AUDIO_CALL = 'IS_AUDIO_CALL'
export const REMOTE_OPTIONS = 'REMOTE_OPTIONS'
export const isMuteVideoCallFirstTime = (isAudioFirstTime) => ({ type: AUDIO_CALL_FIRST_TIME, isAudioFirstTime })
export const setCallingStarted = (isCallingStarted) => ({ type: IS_CALLING_STARTED, isCallingStarted })

export const isAudioCall = (isAudioCall) => ({ type: IS_AUDIO_CALL, isAudioCall })

export const setRemoteOptions = (remoteOptions) => ({ type: REMOTE_OPTIONS, remoteOptions })

