export const TYPE_AUDIO = 'audio';
export const TYPE_VIDEO = 'video';

export const SETTING = 'setting'
export const CALLING = 'calling'
export const WAITING = 'waiting'

export const SPEAKER_ON = 'default'
export const SPEAKER_OFF = 'communications'

export const QUALITIES_VIDEO = [
    {label: '高品質', value: 'high'},
    {label: '中品質', value: 'medium'},
    {label: '低品質', value: 'low'},
];

export const LANGUAGES = [
    {value: "ca-ES", label: "Catalan (Spain)"},
    {value:"de-DE", label: "German (Germany)"},
    {value:"en-GB", label: "English (United Kingdom)"},
    {value:"en-US", label: "English (United States)"},
    {value:"es-US", label: "Spanish (United States)"},
    {value:"fr-FR", label: "French (France)"},
    {value:"it-IT", label: "Italian (Italy)"},
    {value:"nl-NL", label: "Dutch (Netherlands)"},
    {value:"pt-BR", label: "Portuguese (Brazil)"},
    {value:"ro-RO", label: "Romanian (Romania)"},
    {value:"sk-SK", label: "Slovak (Slovakia)"},
    {value:"sl-SI", label: "Slovenian (Slovenia)"},
    {value:"vi-VN", label: "Vietnamese (Vietnam)"},
    {value:"tr-TR", label: "Turkish (Turkey)"},
    {value:"ru-RU", label: "Russian (Russia)"},
    {value:"ko-KR", label: "Korean (South Korea)"},
    {value:"zh-TW", label: "Chinese Mandarin (Traditional, Taiwan)"},
    {value:"ja-JP", label: "Japanese (Japan)"},
    {value:"zh", label: "Chinese Mandarin (Simplified, China)"}
]
