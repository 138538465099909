/* eslint-disable */ 
import store from '../store'

class UsersService {
    getUserById = (userId) => {
        let user = {name: '', user_id: ''};
        const users = store.getState().users;
        
        if (users) {
            const founds = Object.values(users).filter((item) => {
              return (
                  (item?.id && parseInt(item?.id) === parseInt(userId))
                  || item.user_id == userId
                  || item.name == userId);
            });
            if (founds) {
              return founds.shift();
            }
        }
        return user;
    }

  get currentUser() {
    return store.getState().currentUser.user
  }
}

export default new UsersService();


