import {
    AUDIO_CALL_FIRST_TIME, 
    IS_AUDIO_CALL,
    IS_CALLING_STARTED, 
    REMOTE_OPTIONS
} from '../actions/settingInCalling'

export default (state = null, action) => {
    switch (action.type) {
        case AUDIO_CALL_FIRST_TIME:
            const {isAudioFirstTime} = action
            return {...state, isAudioFirstTime}
        case IS_CALLING_STARTED:
            const {isCallingStarted} = action
            return {...state, isCallingStarted}
        case IS_AUDIO_CALL:
            const {isAudioCall} = action
            return {...state, isAudioCall} 
        case REMOTE_OPTIONS:
            const {remoteOptions} = action
            return {...state, remoteOptions}
        default:
            return state
    }
}
