/* eslint-disable */ 
import React, {Component} from 'react'
import {connect} from "react-redux";
class RemoteVideo extends Component {
    
    render() {
        return (
                <div className="video_full">
                    <img alt="" src={require('../../assets/images/avatar.png')}
                         className="avatar-waiting"
                    />
                    <div style={{visibility: "hidden"}} id="sub-title" className="sub-title"></div>
                </div>
        )
    }
}
function mapStateToProps(state) {
    return {
        remoteStream: state.remoteStream,
        setting : state.setting
    };
}
export default connect(mapStateToProps)(RemoteVideo);
