/* eslint-disable */
import React, {Component} from 'react'
import {connect} from "react-redux";
import VideoInputPreview from "../Modal-preCall/VideoInputPreview";
import CallService from "../../services/call-service";
import store from "../../store";
import {setEnableFilter} from "../../actions/filter";
import {TYPE_VIDEO} from "../../helpers/constants";
import { RioLogger } from "../rio-meet/riomeetjs";

class LocalVideo extends Component {

    componentDidMount() {
        const {setting} = this.props;
        const { videoMuted, audioMuted} = setting;
        RioLogger.info('[debug]: setMicDeviceId', JSON.stringify(setting));
        
        if (videoMuted === false) {
            this.setCameraDevice();
        }
        if (audioMuted === false) {
            this.setMicDeviceId();
        }
    }

    setCameraDevice = () => {
        const {videoInput, setting} = this.props;
        let {cameraDeviceId, isFilter, videoMuted} = setting
        const {videos} = videoInput;
        if (videos.length && !cameraDeviceId) {
            cameraDeviceId = videos[0].deviceId
        }
        CallService.setCameraDeviceIdUsing(cameraDeviceId).then(() => {
            CallService.toggleDisableDevice(TYPE_VIDEO, CallService.isVisibleCamera())
        });
        if (isFilter && CallService.isVisibleCamera()) {
            CallService.settingToggleFilter(isFilter, {isGetSize : false})
        }
    }

    setMicDeviceId = () => {
        const {audioInput, setting} = this.props;
        let {micDeviceId} = setting
        const {audios} = audioInput;
        if (audios.length && !micDeviceId) {
            micDeviceId = audios[0].deviceId
        }
        RioLogger.info(`[debug]: setMicDeviceId ${micDeviceId}`);

        CallService.setAudioDeviceIdUsing(micDeviceId);
    }

    render() {
        const {filter, videoInput, setting} = this.props;
        const {videoMute} = setting;
        const classVideoPreview = videoMute ? '' : 'off-minor';
        const classVideoPreviewCanvas = filter && filter.isFiltered ? 'off-minor' : 'off-minor hidden';
        return (
            <div className="video_tag">
                {
                    filter && filter.enableFilter &&
                    <canvas className={classVideoPreviewCanvas} id="canvasPreview"></canvas>
                }
                <VideoInputPreview
                    className={filter && filter.isFiltered ? 'height-width-0' : classVideoPreview}
                    playsinline={true}
                    id="localVideoPreview"
                    style={{width: "100%"}}
                    videoTrack={{rioTrack: videoInput.localVideoTrack}}/>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        filter: state.filter,
        setting: state.setting,
        videoInput: state.VideoInput,
        audioInput: state.audioInput,
    };
}

export default connect(mapStateToProps)(LocalVideo);
