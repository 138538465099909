/* eslint-disable */ 
import React, {Component} from 'react'
import {connect} from "react-redux";
import store from "../../store";
import LocalRecording from "../local-recording";
import Subtitle from "../subTitle";
import CallService from "../../services/call-service";
import { RioHelper } from "../rio-meet/riomeetjs";

class ButtonCall extends Component {

   setAudioMute = () => {
        CallService.setAudioMute();
    }
    
    setVideoMute = async () => {
        const {setting, filter} = this.props;
        if (!setting.videoMuted && filter && filter.enableFilter) {
            await CallService.settingToggleFilter(false);
        }
        setTimeout(() => {
             CallService.setVideoMute();
        }, 100)
    }
    
    setToggleSpeaker = () => {
        CallService.switchSpeaker();
    }
    
    stopCall = () => {
        CallService.stopCall()
    };
    
    componentWillUnmount() {
         store.dispatch(isAudioCall(true))
    }

    enableRecording(callOptions) {
        //disable recording
        //if (RioHelper.isIosBrowser()) {
        //    //disable on iOS
        //    return false;
        //}
        //return callOptions 
        //    && callOptions.initiatorID 
        //    && (callOptions.currentUserID == callOptions.initiatorID)
        return false;
    }

    isShowSpeaker(setting) {
        //disable button on/off speaker
        return (setting && setting.isAudioCall);
    }

    render() {
        const {session, setting, recording, settingInCalling} = this.props;
        const callOptions = session && session.getCallInfo()
        const isMuteAudio = setting.audioMuted ? 'btn_action btn_mic active' : 'btn_action btn_mic'
        const isMuteCamera = setting.videoMuted ? 'btn_action btn_video active' : 'btn_action btn_video'
        const isSpeaker = setting.speaker ? 'btn_action btn-speaker' : 'btn_action btn-speaker active'

        return ( 
        <div className="button_bot">
            {
                recording && recording.timeRecord 
                &&  <div className="btn_action btn_time_record"><i className="time_record">{recording.timeRecord}</i> </div>
            }
            {this.enableRecording(callOptions) && <LocalRecording/>}
            <Subtitle/>
            <div  className={isMuteAudio} onClick={this.setAudioMute}>
                <i className="icon_mic"></i>
            </div>
            <div  className={isMuteCamera} onClick={this.setVideoMute}>
                <i className="icon_video"></i>
            </div>
            {settingInCalling && this.isShowSpeaker(settingInCalling) && 
                <div  className={isSpeaker} onClick={this.setToggleSpeaker}>
                    <i className="icon_speaker"></i>
                </div>
            }
            <div className="btn_call" onClick={this.stopCall}><i className="icon_call"></i></div>
        </div>)
    }
}

function mapStateToProps(state) {
    const {
        session,
        filter,
        recording,
        setting,
        settingInCalling
    } = state
   return {
       session,
       filter,
       recording,
       setting,
       settingInCalling
   }
}

export default connect(mapStateToProps)(ButtonCall);
