/* eslint-disable */ 
import store from "../store";

export const SET_VOICE_CHANGE = 'SET_VOICE_CHANGE'
export const RESET_ALL = 'RESET_ALL'

export const setVoiceChange = (options) => ({type : SET_VOICE_CHANGE, ...options})
export const setConfigPreview = () => {
    const state = store.getState();
    const voiceChanges = state.voiceChange
    for (const config in voiceChanges) {
        state.session.voiceChange({name: config, value: Number(voiceChanges[config])});
    }
}
