import React, {Component} from 'react'
import { connect } from "react-redux";
import CallService from "../../../services/call-service";
import {TYPE_AUDIO} from "../../../helpers/constants";
import { RioLogger } from "../../rio-meet/riomeetjs";

class Audios extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    async onchangeAudioDevice(event) {
        const micDeviceId =  event.target.value
        this.changeAudio(micDeviceId);
        
    }
    
     changeAudio= (micDeviceId) => {
        if (!micDeviceId) {
            CallService.setMuteDeviceForSetting(true, TYPE_AUDIO);
        }
        
        const options = {
            micDeviceId,
            options: {
                audioMuted: false,
            }
        }
        CallService.switchAudio(options);
    }

    render() {
        const {audios, setting, isAudioDisable} = this.props;
        const {micDeviceId} = setting
        const found = audios.filter(device => (device.current == '1' || device.current == 'true'));
        const currentDevice = found.length ? found.shift() : false;
        let currentDeviceId = micDeviceId;
        if(currentDevice && currentDevice?.deviceId) {
            currentDeviceId = currentDevice?.deviceId;
        }

        RioLogger.info(`[debug]: Audios setMicDeviceId ${micDeviceId} ${currentDeviceId}`, JSON.stringify(setting), audios, currentDevice);

        return (
            <div className="line_form">
                <label>オーディオ</label>
                {audios &&
                    <select disabled={isAudioDisable} className="form_setting"
                       onChange={(e) => this.onchangeAudioDevice(e)}
                       defaultValue={currentDeviceId}
                       value = { currentDeviceId }
                    >
                    {audios.map((item, index) => (
                        <option className="label" key={index} value={item.deviceId}>{item.label || 'Speakerphone'}</option>
                    ))}
                </select>
                }
            </div>
        )
    }
}

const mapStateToProps = state => ({
    audios: state.audioInput && state.audioInput.audios,
    setting: state.setting
});

export default connect(mapStateToProps)(Audios);
