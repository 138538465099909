
import React, { Component } from 'react';

class VideoInputPreview extends Component {
    _videoElement;
    _mounted;

    /**
     * Default values for {@code VideoInputPreview} component's properties.
     *
     * @static
     */
    static defaultProps = {
        className: '',
        autoPlay: true,
        id: '',
        playsinline: true
    };

    /**
     * Initializes a new {@code VideoInputPreview} instance.
     *
     * @param {Object} props - The read-only properties with which the new
     * instance is to be initialized.
     */
    constructor(props) {
        super(props);

        /**
         * The internal reference to the DOM/HTML element intended for
         * displaying a video.
         *
         * @private
         * @type {HTMLVideoElement}
         */
        this._videoElement = null;


        // Bind event handlers so they are only bound once for every instance.
        this._onVideoPlaying = this._onVideoPlaying.bind(this);
        this._setVideoElement = this._setVideoElement.bind(this);
    }

    /**
     * Invokes the library for rendering the video on initial display. Sets the
     * volume level to zero to ensure no sound plays.
     *
     * @inheritdoc
     * @returns {void}
     */
    componentDidMount() {
        this._mounted = true;

        if (this._videoElement) {
            this._videoElement.volume = 0;
            this._videoElement.onplaying = this._onVideoPlaying;
        }

        this._attachTrack(this.props.videoTrack);

        if (this._videoElement && this.props.autoPlay) {
            // Ensure the video gets play() called on it. This may be necessary in the
            // case where the local video container was moved and re-attached, in which
            // case video does not autoplay.
            this._videoElement.play()
                .catch(error => {
                    // Prevent uncaught "DOMException: The play() request was interrupted by a new load request"
                    // when video playback takes long to start and it starts after the component was unmounted.
                    if (this._mounted) {
                        // throw error;
                    }
                });
        }
    }

    /**
     * Remove any existing associations between the current video track and the
     * component's video element.
     *
     * @inheritdoc
     * @returns {void}
     */
    componentWillUnmount() {
        this._mounted = false;
        this._detachTrack(this.props.videoTrack);
    }

    /**
     * Updates the video display only if a new track is added. This component's
     * updating is blackboxed from React to prevent re-rendering of video
     * element, as the lib uses {@code track.attach(videoElement)} instead.
     *
     * @inheritdoc
     * @returns {boolean} - False is always returned to blackbox this component
     * from React.
     */
    shouldComponentUpdate(nextProps) {
        const currentRioTrack = this.props.videoTrack
            && this.props.videoTrack.rioTrack;
        const nextRioTrack = nextProps.videoTrack
            && nextProps.videoTrack.rioTrack;

        if (currentRioTrack !== nextRioTrack) {
            this._detachTrack(this.props.videoTrack);
            this._attachTrack(nextProps.videoTrack);
        }

        if (this.props.style !== nextProps.style || this.props.className !== nextProps.className) {
            return true;
        }

        return false;
    }

    full_bleed(boxWidth = 0, boxHeight = 0, imgWidth = 0, imgHeight = 0)
    {
        const $video = document.getElementById('localVideoPreview');
        if ($video) {
            const $parent = $video.parentElement
            imgWidth  = $video.offsetWidth;
            imgHeight = $video.offsetHeight;
            boxWidth  = $parent.offsetWidth;
            boxHeight = $parent.offsetHeight;
            // Calculate new height and width
            const initW = imgWidth;
            const initH = imgHeight;
            const ratio = initH / initW;

            imgWidth = boxWidth;
            imgHeight = boxWidth * ratio;

            if(imgHeight < boxHeight){
                imgHeight = boxHeight;
                imgWidth = imgHeight / ratio;
            }
            // if(imgHeight > boxHeight){
            //     imgHeight = boxHeight;
            //     imgWidth = imgHeight / ratio;
            // }
            //  Return new size
            this.setState({
                width: imgWidth,
                height: imgHeight
            })
            return {
                width: imgWidth,
                height: imgHeight
            };
        }
       
    }
    
    /**
     * Renders the video element.
     *
     * @override
     * @returns {ReactElement}
     */
    render() {
        const {
            autoPlay,
            className,
            id,
            muted,
            playsinline,
            style,
            eventHandlers
        } = this.props;

        return (
            <video
                autoPlay = { autoPlay }
                className = { className }
                id = { id }
                muted = { muted }
                playsInline = { playsinline }
                ref = { this._setVideoElement }
                style = { style }
                { ...eventHandlers } />
        );
    }

    /**
     * Calls into the passed in track to associate the track with the
     * component's video element and render video.
     *
     * @param {Object} videoTrack - The redux representation of the
     * {@code rioLocalTrack}.
     * @private
     * @returns {void}
     */
    _attachTrack(videoTrack) {
        if (!videoTrack || !videoTrack.rioTrack) {
            return;
        }

        if (this._videoElement) {
            this._videoElement.pause();
        }
        videoTrack.rioTrack.attach(this._videoElement);
        if (this._videoElement && this.props.autoPlay) {
            // Ensure the video gets play() called on it. This may be necessary in the
            // case where the local video container was moved and re-attached, in which
            // case video does not autoplay.
            this._videoElement.onloadedmetadata = (e) => {
                this._videoElement.play()
                .catch(err => {
                    // Prevent uncaught "DOMException: The play() request was interrupted by a new load request"
                    // when video playback takes long to start and it starts after the component was unmounted.
                    if (this._mounted) {
                        // throw error;
                    }
                });
            };
        } else {
            this._videoElement.onloadedmetadata = (e) => {
                this._videoElement.play()
                .catch(err => {
                });
            };
        }
    }

    /**
     * Removes the association to the component's video element from the passed
     * in redux representation of rio video track to stop the track from
     * rendering.
     *
     * @param {Object} videoTrack -  The redux representation of the
     * {@code rioLocalTrack}.
     * @private
     * @returns {void}
     */
    _detachTrack(videoTrack) {
        if (this._videoElement && videoTrack && videoTrack.rioTrack) {
            videoTrack.rioTrack.detach(this._videoElement);

            this._videoElement.pause();
            this._videoElement.srcObject = null;
        }
    }


    /**
     * Invokes the onvideoplaying callback if defined.
     *
     * @private
     * @returns {void}
     */
    _onVideoPlaying() {
        if (this.props.onVideoPlaying) {
            this.props.onVideoPlaying();
        }
    }

    /**
     * Sets an instance variable for the component's video element so it can be
     * referenced later for attaching and detaching a rioLocalTrack.
     *
     * @param {Object} element - DOM element for the component's video display.
     * @private
     * @returns {void}
     */
    _setVideoElement(element) {
        this._videoElement = element;
    }
}

export default VideoInputPreview;
