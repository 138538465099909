import {
  IS_SHOW_LOADER,
  RESET_LOADER
} from '../actions/loader'

export default (loader = null, action) => {
  switch (action.type) {
    case IS_SHOW_LOADER:
      return action.isShowLoader;

    case RESET_LOADER:
        return null;

    default:
      return loader
  }
}