if (!window.RioMeetJS) {
    var RioMeetJS = {};
}
RioMeetJS = window.RioMeetJS;

//create instance RioMeetJS
if (typeof window.RioMeetJS === 'object') {
  RioMeetJS.initInstance();
}

export default RioMeetJS;

export const RioHelper = window.RioHelper || {};
export const RioLogger = RioMeetJS.util.logger || {};
export const browser = RioMeetJS.util.browser;