import {
    RECORDING_INPROCESS, SET_DISABLE,
    SET_INTERVAL_COUNT_TIME, SET_NUMBER_TIME_RECORD,
} from '../actions/recording'

export default (state = {}, action) => {
    switch (action.type) {
        case RECORDING_INPROCESS:
            const {inProcess} = action
            return {...state, inProcess}
        case SET_INTERVAL_COUNT_TIME:
            const {interval} = action
            return {...state, interval}    
        case SET_DISABLE:
            const {isDisabled} = action
            return {...state, isDisabled} 
        case SET_NUMBER_TIME_RECORD:
            const {timeRecord} = action
            return {...state, timeRecord}
        default:
            return state
    }
}
