/* eslint-disable */
import React, {Component} from 'react'
import {connect} from "react-redux";
import CallService from "../../services/call-service";
import RemoteVideo from "./remoteVideo";
import ButtonCall from "./buttonCall";
import Helper from "../../helpers/helpers";
import {Redirect} from "react-router-dom";
import LocalVideo from "./localVideo";
import HeaderCall from "../call/headerCall";

class WaitingCall extends Component {

    componentWillUnmount() {
        CallService.removeAllTrackAndDevicePreview();
    }

    componentDidMount() {
        setTimeout(() => {
            if (Helper.getParam('webview-web') == 11) {
                CallService.join();
            }
        }, 5000)
    }
    
     getUserReceiver = () => {
        const {setting} = this.props
        
        console.log('setting [debug]', JSON.stringify(setting))
        
        return setting?.to
    }

    render() {
        const {videoInput, audioInput, settingInCalling} = this.props
        return (
            <div id="videochat-streams-setting">
                <RemoteVideo/>
                {videoInput && audioInput && <LocalVideo/>}
                {videoInput && audioInput && <HeaderCall userId={this.getUserReceiver()}/>}
                {videoInput && audioInput && <ButtonCall/>}
                {settingInCalling
                    && settingInCalling.isCallingStarted
                    && <Redirect to="/calling"/>
                }
            </div>
        )
    }
}

function mapStateToProps(state) {
    const {
        audioInput,
        session,
        currentUser,
        settingInCalling,
        setting
    } = state
    const videoInput = state.VideoInput
    return {
        videoInput,
        audioInput,
        session,
        currentUser,
        settingInCalling,
        setting,
    }
}

export default connect(mapStateToProps)(WaitingCall);
