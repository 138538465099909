import {
  SET_SNACKBAR,
  RESET_SNACKBAR
} from '../actions/snackbar'

export default (snackbar = {}, action) => {
  switch (action.type) {
    case SET_SNACKBAR:
        const newObj = {
            isShowSnackbar: action.isShowSnackbar,
            text: action.text
        }
      return newObj

    case RESET_SNACKBAR:
      return {}

    default:
      return snackbar
  }
}