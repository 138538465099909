class Helper {
    init = () => {
    }

    getParam = (name, defVal) => {
        const url = new URL(window.location.href)
        const params = new URLSearchParams(url.search);
        let val = params.get(name) // 'chrome-instant'
        if(val === undefined || val === null) {
            return defVal || ''
        }
        return val;
    };

    isMuted(localTrack) {
        // this.stream will be null when we mute local video on Chrome
        if (!localTrack || !localTrack.stream) {
            return true;
        }
        if (localTrack.isVideoTrack() && !this.isActive(localTrack.stream)) {
            return true;
        }
        return !localTrack.track || !localTrack.track.enabled;
    }

    isActive(stream) {
        if (typeof stream.active !== 'undefined') {
            return stream.active;
        }
        return true;
    }
    
   isIOS() {
       return /iPad|iPhone|iPod/.test(navigator.platform) || 
       (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
   }

   getAudioInput = (devices) => {
        const devices1 = devices.filter(device => (device.deviceId === 'default' || device.deviceId === '') && device.kind === 'audioinput');
        const devices2 = devices.filter(device => (device.deviceId !== 'default' && device.deviceId !== '') && device.kind === 'audioinput');
        if (devices2.length > 0) {
            return devices2;
        }
        return devices1;
    };
    
    getAudioOutput = (devices) => {
        const speakerDevices = devices.filter(device => device.kind === 'audiooutput');
        return speakerDevices;
    };
};

// create instance
const helper = new Helper();
// lock instance
Object.freeze(helper);

export default helper;
