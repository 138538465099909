import React, {Component} from 'react'
import {connect} from "react-redux";
class Filter extends Component {
    onclickFilter =  (event) => {
        this.props.changeFilter && this.props.changeFilter(event.target.checked);
    }
    
   checkVideo = () => {
        const {setting} = this.props;
        return setting && setting.videoMuted;
    }

    render() {
        const {filter} = this.props;
        const videoMuted = this.checkVideo();
        return (
            <div className="line_form">
                <label>美肌フィルター</label>
                <label className="switch">
                    <input type="checkbox"
                           onChange={this.onclickFilter}
                           checked= {!!(filter && filter.enableFilter)}
                           disabled={videoMuted || (filter && filter.inProcess)}
                    />
                        <span className="slider round"/>
                </label>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        filter: state.filter,
        setting : state.setting
    };
}

export default connect(mapStateToProps)(Filter);

