export const IS_FILTER = 'IS_FILTER'
export const IS_MUTE_CAMERA = 'IS_MUTE_CAMERA'
export const IS_MUTE_AUDIO = 'IS_MUTE_AUDIO'
export const SET_TYPE_VOICE_CHANGE = 'SET_TYPE_VOICE_CHANGE'
export const SET_CAMERA_DEVICE_ID = 'SET_CAMERA_DEVICE_ID'
export const SET_MIC_DEVICE_ID = 'SET_MIC_DEVICE_ID'
export const SET_INIT_CONFIG = 'SET_INIT_CONFIG'
export const SET_SPEAKER_DEVICE_ID = 'SET_SPEAKER_DEVICE_ID'
export const SET_IS_SPEAKER = 'SET_IS_SPEAKER'

export const setIsFilter = (isFilter) => ({ type: IS_FILTER, isFilter })
export const setIsMuteCamera = (videoMuted) => ({ type: IS_MUTE_CAMERA, videoMuted })
export const setIsMuteAudio = (audioMuted) => ({ type: IS_MUTE_AUDIO, audioMuted })
export const setVoice = (voice) => ({ type: SET_TYPE_VOICE_CHANGE, voice })
export const setCameraDeviceId = (cameraDeviceId) => ({ type: SET_CAMERA_DEVICE_ID, cameraDeviceId })
export const setMicDeviceId = (micDeviceId) => ({ type: SET_MIC_DEVICE_ID, micDeviceId })
export const setSpeakerDeviceId = (speaker) => ({ type: SET_SPEAKER_DEVICE_ID, speaker })
export const setInitConfig = (setting) => ({ type: SET_INIT_CONFIG, setting })
