import {
  SET_AUDIO_OUTPUT, SET_AUDIO_OUTPUT_DEVICE,
} from '../actions/audioOutput'

export default (state = null, action) => {
  switch (action.type) {
    case SET_AUDIO_OUTPUT:
      const {speakers} = action
      return {...state, speakers}
    case SET_AUDIO_OUTPUT_DEVICE:
      const {audioDeviceId} = action
      return {...state, audioDeviceId}
    default:
      return state
  }
}
