/* eslint-disable */ 
import React, {Component} from 'react'
import {connect} from "react-redux";
import RioMeetJS from "../rio-meet/riomeetjs";
class LocalVideo extends Component {
    getClassLocalStream = () => {
        const {setting} = this.props;
        return setting.cameraDeviceId != RioMeetJS.constants.CameraFacingMode.ENVIRONMENT ? 'off-minor videochat-stream' : 'videochat-stream';
    }

    render() {
        const { filter} = this.props;
        const classLocalStream = this.getClassLocalStream();
        const canvasClass = `deepar ${classLocalStream} d-hidden`;
        return (
             <div className="video_tag">
                <video 
                    playsInline={true} 
                    id="localStream" 
                    autoPlay 
                    width="100%"
                    className= {classLocalStream} 
                />
               <img 
                   data-html2canvas-ignore="true"
                   id="localStream-img" 
                   className="html2canvas-ignore avatar-local d-hidden" 
                   width="100%" alt="" 
                   src={require('../../assets/images/avatar.png')}
               />
                {
                    filter && filter.enableFilter &&
                    <canvas className={canvasClass} id="deeparCanvas"></canvas>
                }
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        filter: state.filter,
        setting : state.setting
    };
}

export default connect(mapStateToProps)(LocalVideo);
