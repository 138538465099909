/* eslint-disable */
import React, {Component} from 'react'
import SettingInCalling from "./settingInCalling";
import {connect} from "react-redux";
import RemoteVideo from "./remoteVideo";
import LocalVideo from "./localVideo";
import ButtonCall from "./buttonCall";
import HeaderCall from "./headerCall";
import UsersService from "../../services/users-service";

class Calling extends Component {
    constructor(props) {
        super(props)
        this.state = {
            callId: false,
        }
    }

    getUserReceiver = () => {
        const {session} = this.props
        const callOptions = session && session.getCallInfo();
        let userId = ''
        if (callOptions) {
            const {currentUserID, initiatorID, from, to} = callOptions
            userId = currentUserID == initiatorID ? to : from
        }
        return userId;
    }

    render() {
        const {videoInput, audioInput} = this.props
        return (
            <div id="videochat-streams">
                {videoInput && audioInput && <RemoteVideo/>}
                {videoInput && audioInput && <LocalVideo/>}
                {videoInput && audioInput && <HeaderCall userId={this.getUserReceiver()}/>}
                {videoInput && audioInput && <SettingInCalling/>}
                {videoInput && audioInput && <ButtonCall/>}
            </div>
        )
    }
}

function mapStateToProps(state) {
    const {
        audioInput,
        snackbar,
        session,
        currentUser,
        filter
    } = state
    const videoInput = state.VideoInput
    return {
        videoInput,
        audioInput,
        snackbar,
        session,
        currentUser,
        filter
    }
}

export default connect(mapStateToProps)(Calling);
