/* eslint-disable */
import React, {Component} from 'react'
import UsersService from "../../services/users-service";
import {connect} from "react-redux";
import CallService from "../../services/call-service";
import RioMeetJS from "../../components/rio-meet/riomeetjs";

import { RioLogger } from "../rio-meet/riomeetjs";

class HeaderCall extends Component {

    slideOutPanel;
    changeCamera = (cameraDeviceId) => {
        if (!cameraDeviceId) {
            CallService.setMuteDeviceForSetting(true)
        }
        if (cameraDeviceId == RioMeetJS.constants.CameraFacingMode.ENVIRONMENT) {
            cameraDeviceId = RioMeetJS.constants.CameraFacingMode.USER
        } else {
            cameraDeviceId = RioMeetJS.constants.CameraFacingMode.ENVIRONMENT
        }
        const options = {
            cameraDeviceId,
            options: {
                videoMuted: false,
            }
        }
        CallService.switchCamera(options);
    }
    
    getSnapshotBeforeUpdate(prevProps, prevState){

        RioLogger.debug(`[debug]: changeCamera1 `, JSON.stringify(prevProps));

        if (prevProps.settingInCalling && prevProps.settingInCalling.isCallingStarted ) {
            const {isCallingStarted} = prevProps.settingInCalling;
            return {isCallingStarted};
        }
        return null
    }
    
    componentDidUpdate(prevProps, prevState, snapshot) {
        RioLogger.debug(`[debug]: changeCamera2 `, JSON.stringify(prevProps), JSON.stringify(snapshot));
        if (snapshot && snapshot.isCallingStarted && !this.slideOutPanel) {
            this.slideOutPanel = $('#slide-out-panel').SlideOutPanel({});
        }
    }

    openSetting() {
        this.slideOutPanel.open();
    }

    isCallStarted() {
        const {settingInCalling} = this.props
        RioLogger.debug(`[debug]: changeCamera3 `, JSON.stringify(this.props));
        return settingInCalling && settingInCalling.isCallingStarted
    }

    render() {
        const {setting, userId} = this.props
        const classSwitchCam = !setting.videoMuted ? "mr-5 btn_change_cam" : 'mr-5 btn_change_cam d-none'
        const {cameraDeviceId} = setting
        const user = UsersService.getUserById(userId);
        //console.log('userId--', userId, user)
        RioLogger.debug(`[debug]: changeCamera4 `, JSON.stringify(this.props));

        return (
            <div className="header_top">
                <div className="left_text" id="timer-id">
                    <h4>{user?.name}</h4>
                    {this.isCallStarted() && <span className="timer">00:00</span>}
                </div>
                {this.isCallStarted() && 
                    <div className="right_btn">
                        <i
                            className={classSwitchCam}
                            onClick={() => this.changeCamera(cameraDeviceId)}>
                        </i>
                        <i className=" mr-5 btn_setting"
                           onClick={() => this.openSetting()}
                        ></i>
                    </div>
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    videos: state.VideoInput.videos,
    setting: state.setting,
    session: state.session,
    settingInCalling: state.settingInCalling
})

export default connect(mapStateToProps)(HeaderCall);

