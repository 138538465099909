/* eslint-disable */
import React, {Component} from 'react'
import {connect} from "react-redux";
import CallService from "../../services/call-service";
import {TYPE_AUDIO, TYPE_VIDEO} from "../../helpers/constants";

class ButtonCall extends Component {

    toggleDisableCamera = async (e) => {
        e.preventDefault();
        await CallService.toggleDisableDevice(TYPE_VIDEO);
    }

    toggleDisableAudio = async (e) => {
        e.preventDefault();
         await CallService.toggleDisableDevice(TYPE_AUDIO)
    }
    
    setToggleSpeaker = () => {
        const {setting} = this.props
        const isSpeaker = !setting.speaker;
        CallService.setSpeakerForSetting(isSpeaker);
    }

    stopCall = () => {
        CallService.stopCall()
    };

    render() {
         const {setting} = this.props;
        const isMuteAudio = setting.audioMuted ? 'btn_action btn_mic active' : 'btn_action btn_mic'
        const isMuteCamera = setting.videoMuted ? 'btn_action btn_video active' : 'btn_action btn_video'
        const isSpeaker = setting.speaker ? 'btn_action btn-speaker' : 'btn_action btn-speaker active'

        return (
        <div className="button_bot">

            <div  className={isMuteAudio} onClick={this.toggleDisableAudio}>
                <i className="icon_mic"></i>
            </div>
            <div  className={isMuteCamera} onClick={this.toggleDisableCamera}>
                <i className="icon_video"></i>
            </div>
            {/*<div className={isSpeaker}  onClick={this.setToggleSpeaker}>
                <i className="icon_speaker"></i>
            </div>*/}
            <div className="btn_call" onClick={this.stopCall}><i className="icon_call"></i></div>
        </div>)
    }
}

function mapStateToProps(state) {
    const {
        session,
        filter,
        recording,
        setting
    } = state
    const {videoInput} = state.VideoInput
   return {
       session,
       filter,
       recording,
       setting,
       videoInput
   }
}

export default connect(mapStateToProps)(ButtonCall);
