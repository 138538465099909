import {
  SET_IS_VISIBLE_REMOTE_STREAM,
  RESET_REMOTE_STREAM,
  WIDTH_REMOTE_STREAM,
  SET_REMOTE_TRACKS
} from '../actions/remoteStream'

export default (remoteStream = {}, action) => {
  switch (action.type) {
    case SET_IS_VISIBLE_REMOTE_STREAM:
      remoteStream.isVisible = action.isVisible;
      return { ...remoteStream };
    case RESET_REMOTE_STREAM:
      return {};
    case WIDTH_REMOTE_STREAM:
      const {width} = action;
      return { ...remoteStream, width};
    case SET_REMOTE_TRACKS:
      const {remoteTrack} = action;
      let length = Object.keys(remoteStream).length
      remoteStream[length] = remoteTrack
      return { ...remoteStream};
    default:
      return remoteStream
  }
}
