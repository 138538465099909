/* eslint-disable */ 
import Helper from '../helpers/helpers';
import RioMeetJS from '../components/rio-meet/riomeetjs';
import { RioLogger, RioHelper } from "../components/rio-meet/riomeetjs";
import {browser} from '../components/rio-meet/riomeetjs';
import store from "../store";
import UsersService from "../services/users-service";
import {resetPreCall} from "../actions/preCall";
import {setSnackbar} from "../actions/snackbar";
import {resetLoader} from "../actions/loader";
import {resetSession, setSession} from "../actions/session";
import {CALLING, SETTING, TYPE_AUDIO, TYPE_VIDEO} from "../helpers/constants";
import {
    resetRemoteStream,
    setIsVisibleRemoteStream,
    setRemoteTracks,
} from "../actions/remoteStream";
import {setCameraDevice} from "../actions/videoInput";
import {setAudioDevice} from "../actions/audioInput";
import {setLocalStream} from "../actions/localStream";
import { appConfig, callOptions } from "../config"
import {setRecordingInProcess} from "../actions/recording";
import { resetFilter, setEnableFilter, setInProcess, setRemoteEnableFilter} from "../actions/filter";
import { RioAppClient, getAvailableDevices} from "../components/appClient/rioappclient";
import Timer from './Timer';
import {
    setCameraDeviceId,
    setInitConfig,
    setIsMuteAudio,
    setIsMuteCamera,
    setMicDeviceId, setSpeakerDeviceId,
    setVoice
} from "../actions/setting";
import {
    isMuteVideoCallFirstTime, 
    setCallingStarted, 
    setRemoteOptions, 
    isAudioCall
} from "../actions/settingInCalling";

import FilterFace from "../services/filterFace";
import {setIsDisablePip, setIsTurnOnPip} from "../actions/pip";
import {setAudioInputs} from "../actions/audioInput";

const iOS = window.device?.platform === "iOS";
const LANGUAGE = 'ja-JP'

class CallService {

    init = () => {
        RioMeetJS.videoroom.onStopCallListener = this.onStopCallListener.bind(this);
        
        RioMeetJS.videoroom.onRemoteStreamListener = this.onRemoteStreamListener.bind(this);
        RioMeetJS.videoroom.onRemoteUpdated = this.onRemoteUpdated.bind(this);
        RioMeetJS.videoroom.onConnectionSuccess = this.onConnectionSuccess.bind(this);
        RioMeetJS.videoroom.onConnectionFailed = this.onConnectionFailed.bind(this);
        RioMeetJS.videoroom.onLeaveRoom = this.onLeaveRoom.bind(this);
        RioMeetJS.videoroom.onTrackMutedListener = this.onTrackMutedListener.bind(this);
        RioMeetJS.videoroom.onToggleEffect = this.onToggleEffect.bind(this);
        RioMeetJS.videoroom.onDisconnect = this.onDisconnect.bind(this);
        RioMeetJS.videoroom.onSubtitle = this.onSubtitle.bind(this)
        RioMeetJS.videoroom.onSpeakerEnable = this.onSpeakerEnable.bind(this);
        RioMeetJS.videoroom.onAudioInputChanged = this.onAudioInputChanged.bind(this);
        RioMeetJS.videoroom.onChangeAudioDeviceId = this.onChangeAudioDeviceId.bind(this);
        RioMeetJS.videoroom.onLeavePIP = this.onLeavePIP.bind(this)
        RioMeetJS.videoroom.onRemoteReadyState = this.onRemoteReadyState.bind(this)
    };

    mediaParams = {
        audio: false,
        video: true,
        localStreamId: "localStream",
        remoteStreamId: "remoteStream",
        options: {
            videoMuted: false,
            audioMuted: false,
            mirror: true
        }
    };

    timeOutSubTitle = null;
    data = {
        devices: []
    };

    addStreamElements = opponents => {
        console.log(opponents);
    };

    onCallListener = async (session, options) => {
        if (this._getSession()) {
            await this.rejectCall(session, {busy: true});
            return false;
        }
        await store.dispatch(setSession(session));
    };

    onAcceptCallListener = (session, userId) => {
        if (userId === session.currentUserID) {
            store.dispatch(resetSession());
            store.dispatch(setSnackbar(true, 'You have accepted the call on other side'))
            return false;
        } else {
            const user = UsersService.getUserById(userId);
            const name = user.name || ' '
            const infoText = name + ` has accepted the call`;
            store.dispatch(setSnackbar(true, infoText))
            store.dispatch(resetLoader());
            store.dispatch(setIsVisibleRemoteStream(false));
        }
    };

    onRejectCallListener = (session, userId, extension = {}) => {
        if (userId === session.currentUserID) {
            store.dispatch(resetSession());
            store.dispatch(setSnackbar(true, "You have rejected the call on other side"))
            return false;
        } else {
            const user = UsersService.getUserById(userId);
            const infoText = extension.busy ? `${user.name || ' '} is busy` : `${user.name || ' '} rejected the call request`;
            this.stopCall();
            store.dispatch(setSnackbar(true, infoText))
        }
    };

    onStopCallListener = (userId, reason) => {
        let infoText = '';
         const { initiatorID, currentUserID } = RioAppClient.getCallInfo();
        let isStoppedByInitiator = initiatorID === userId;
        if (userId == RioMeetJS.constants.FORCE_STOP_ID) {
            isStoppedByInitiator = true;
            infoText = `Administrator has stopped the call`;
        } else {
            const user = UsersService.getUserById(userId);
            infoText = `${user.name || ''} has ${isStoppedByInitiator ? "stopped" : "left"} the call`;
        }

        store.dispatch(setSnackbar(true, infoText));
        if (isStoppedByInitiator) {
            this.stopCall();
        } else {
            this.stopCall(userId);
        }
    };

    onUserNotAnswerListener = (session, userId) => {
        if (!this._getSession()) {
            return false;
        }
        this.stopCall();
        if (session.currentUserID !== userId) {
            const user = UsersService.getUserById(userId);
            const infoText = `${user.name || ' '} did not answer`;
            store.dispatch(setSnackbar(true, infoText))
        }
    };

    onUserBusyListener = (session, userId) => {
        const {currentUserID} = this._getSession();
        if (userId === currentUserID) {
            const infoText = `You are on other calling`;
            store.dispatch(setSnackbar(true, infoText))
            this.stopCall();
        } else {
            this.stopCall();
            const user = UsersService.getUserById(session.calleeId);
            const infoText = `${user.name|| ' '} is busy`;
            store.dispatch(setSnackbar(true, infoText))
        }
    };

    onRemoteUpdated = (options) => {
        RioLogger.log('onRemoteUpdated--', options)
        store.dispatch(setRemoteOptions(options));
    }
    onRemoteStreamListener = (stream, options) => {
        RioLogger.log('onRemoteStreamListener--', options)
        if (!this._getSession()) {
            return false;
        }
        const {remoteStreamId} = this.mediaParams;
        store.dispatch(setRemoteTracks(stream))
        this._getSession().attachMediaStream(remoteStreamId, stream);
    };

    rejectCall = (session, extension = {}) => {
        if (session) {
            session.reject(extension);
        } else {
            if (this._getSession()) {
                this._getSession().reject(extension);
                store.dispatch(resetSession());
            }
             this.removeAllTrackAndDeviceInCalling();
        }
    };

    join = () => {
        const from = 'test_12a@gmail.com';
        const to = 'test_123a@gmail.com';
        const roomName = '04b899192041ee4253497419b0fda5f2';
        const callId = 2072;
        const caller = Helper.getParam('caller');
        const currentUserID = caller ? from : to;
        const callInfo = {
            callId,
            from,
            to,
            currentUserID,
            roomName,
        }
        const options = {...callOptions, ...callInfo};
        RioLogger.log('[debug] join', options);
        RioAppClient.join(JSON.stringify(options));
    }

    stopCall = async userId => {
        const {recording} = store.getState();
        if (recording && recording.inProcess) {
            await this.stopLocalRecording();
        }
        await this.settingToggleFilter(false);
        Timer.stopTimer();
        this.disposeStream();
        if (!userId) {
            RioAppClient.stop();
        }
        this.resetStoreDefault();
    }

    onConnectionSuccess = async (data) => {
        store.dispatch(setCallingStarted(true))
        const {startTime} = data;
        const {setting} = store.getState();
        const {cameraDeviceId, micDeviceId, videoMuted, audioMuted, isFilter, speaker} = setting;

        const isCallAudio = this.isAudioCall(data);
        RioLogger.info('[debug] onConnectionSuccess', data, setting);
        let session = this._getSession()
        if (!session) {
            session = RioMeetJS.createRioApp();
            store.dispatch(setSession(session));
        }
        Timer.init(startTime);
        const mediaParams = await this.getSettingDevices(this.mediaParams);
        //await this._initDeviceList();
        store.dispatch(isAudioCall(isCallAudio));
        if (cameraDeviceId == RioMeetJS.constants.CameraFacingMode.ENVIRONMENT) {
            this.setDeviceIdForSetting(RioMeetJS.constants.CameraFacingMode.ENVIRONMENT, TYPE_VIDEO)
        } else {
            this.setDeviceIdForSetting(RioMeetJS.constants.CameraFacingMode.USER, TYPE_VIDEO)
        }

        const {localStreamId, remoteStreamId} = this.mediaParams;
        //const mirror = cameraDeviceId  != RioMeetJS.constants.CameraFacingMode.ENVIRONMENT;
        let audioStated = !audioMuted;
        let isVideoType = !(isCallAudio || videoMuted);

        const {disableAudio} = appConfig;
        if (disableAudio === true) {
            //ignore create audio track on iOS app
            audioStated = false;
        }

        const videoOptions = Object.assign({},
            mediaParams,
            {
                video: isVideoType,
                audio: audioStated,
                options: {
                    videoMuted: videoMuted,
                    audioMuted: audioMuted,
                    isFilter, 
                    speaker,
                    // mirror
                },
                cameraDeviceId,
                micDeviceId: micDeviceId || '',
                localStreamId: localStreamId || '',
                remoteStreamId: remoteStreamId || '',
            });

        session.getUserMedia(videoOptions).then(streams => {
            return streams
        }).then(async (streams) => {
            store.dispatch(setLocalStream(streams));
            streams.forEach((stream) => {
                const isMuted = Helper.isMuted(stream);
                if (stream.type === TYPE_VIDEO) {
                    store.dispatch(setCameraDevice({cameraDeviceId : stream.deviceId, localVideoTrack: stream}));
                }
                if (stream.type === TYPE_AUDIO) {
                    store.dispatch(setAudioDevice({localAudioTrack: stream}));
                }
                //this.setDeviceIdForSetting(stream.deviceId, stream.type)
                //this.setMuteDeviceForSetting(isMuted, stream.type)
            })
            if(videoMuted){
               this.setMuteDeviceForSetting(true)
            }
            store.dispatch(isMuteVideoCallFirstTime(videoMuted))
        }).then( () => {
              this.initSettingInCallingScreen();
        }).catch(err => {
            RioLogger.warn(`Error: onConnectionSuccess:`, err);
        });
    };

    async getSettingDevices(mediaParams) {

        let options = {};
        const state = store.getState();
        const {localVideoTrack, cameraDeviceId} = state.VideoInput;
        const {localAudioTrack, audioDeviceId} = state.audioInput;

        if (localVideoTrack) {
            options.videoMuted = Helper.isMuted(localVideoTrack);
            this.destroyStream(localVideoTrack.stream)
            store.dispatch(setCameraDevice({cameraDeviceId, localVideoTrack: ''}));
        }
        if (localAudioTrack) {
            options.audioMuted = Helper.isMuted(localAudioTrack);
            store.dispatch(setAudioDevice({audioDeviceId, localAudioTrack: ''}));
        }
        mediaParams.options = options;
        return mediaParams;
    }

    onConnectionFailed = (err) => {
        RioLogger.log('[debug] onConnectionFailed', err);
        this.stopCall();
    };

    onLeaveRoom = (userId) => {
        RioLogger.log(`onLeaveRoom`, userId);
        this.removeAllTrackAndDeviceInCalling();
    };

    onTrackMutedListener = (track) => {
        const {type, muted} = track;
        RioLogger.log(`onTrackMutedListener`, type, muted);

        if (type === TYPE_VIDEO) {
            store.dispatch(setIsVisibleRemoteStream(muted))
        }
    };

    /**
     * callback when application trigger
     *
     * @param {Object} data 
     * @returns {void} 
     */
    onChangeAudioDeviceId = (data) => {
        RioLogger.log('[debug] : onChangeAudioDeviceId', data);
        const cameraChangeSupported = RioMeetJS.mediaDevices.isDeviceChangeAvailable('input');
        const speakerChangeSupported = RioMeetJS.mediaDevices.isDeviceChangeAvailable('output');
        let disableAudioInputChange = !RioMeetJS.mediaDevices.isMultipleAudioInputSupported();
        
        RioLogger.log(`onChangeAudioDeviceId getAudioOutputDevice: ${RioMeetJS.mediaDevices.getAudioOutputDevice()}
            cameraChangeSupported: ${cameraChangeSupported}
            speakerChangeSupported: ${speakerChangeSupported}
            disableAudioInputChange: ${disableAudioInputChange}
        `);
    };

    /**
     * callback when application trigger
     *
     * @param {Object} data 
     * @returns {void} 
     */
    onAudioInputChanged = (devices) => {
        const found = devices.filter(device => (device.current == '1' || device.current == 'true'));
        const currentDevice = found.length ? found.shift() : false;
        RioLogger.info('[debug] : onAudioInputChanged', devices, currentDevice);

        this.data['devices'] = devices;
        store.dispatch(setAudioInputs(devices));
        if(currentDevice && currentDevice?.deviceId) {
            this.setDeviceIdForSetting(currentDevice.deviceId, TYPE_AUDIO)
        }
    };

    /**
     * callback when application trigger
     *
     * @param {Boolean} isSpeaker 
     * @returns {void} 
     */
    onSpeakerEnable = (isSpeaker) => {
        this.setSpeakerForSetting(isSpeaker);
    };
    
    onLeavePIP = (isRemoteVideo = false) =>{
        RioLogger.log('[debug] onLeavePIP-- ', isRemoteVideo)
    }

    onToggleEffect = (options) => {
        const {remoteStreamId} = this.mediaParams;
        if (options.type === TYPE_AUDIO) {
            const {remoteTracks} = store.getState().remoteStream
            if (remoteTracks && remoteTracks[TYPE_AUDIO]) {
                this._getSession().attachMediaStream(remoteStreamId, remoteTracks[TYPE_AUDIO]);
            }
        }
         
        if (options) {
            let filter = store.getState().filter;
            if (filter.enableRemoteFilter !== options.enabled) {
                store.dispatch(setRemoteEnableFilter(options.enabled))
            }
        }
    }

    onDisconnect = (retry) => {
        RioLogger.log(`onDisconnect`, retry);
    };
    
    /**
     * if state > 0 is ready other not
     * @param state
     */
    onRemoteReadyState = (state) => {
       console.log('[debug] onRemoteReadyState', state)
       store.dispatch(setIsDisablePip(state < 1))
    }
    
    setAudioMute = () => {
        let {setting, localStream} = store.getState();
        
        if (!localStream.localTracks) {
            return false;
        }
        
        let isAudioMuted = !setting.audioMuted;
        RioLogger.info('[debug] setAudioMute', isAudioMuted);

        this._getSession().setMute(isAudioMuted, TYPE_AUDIO).then(devices => {
            this.setMuteDeviceForSetting(isAudioMuted, TYPE_AUDIO);
        });
    };

    setVideoMute = async() => {
        const self = this;
        let {setting, settingInCalling, localStream} = store.getState();
        if (!localStream.localTracks || !localStream.localTracks.length) {
            return false;
        }
        
        if (!settingInCalling.isAudioFirstTime && !this.checkExistVideoOrAudioTrack(localStream.localTracks, TYPE_VIDEO)) {
            return false
        }
        
        let isVideoMuted = !setting.videoMuted;
        const callInfo = RioAppClient.getCallInfo();
        if (!isVideoMuted && settingInCalling.isAudioFirstTime){
            this.switchToVideoCall(callInfo);
            store.dispatch(isMuteVideoCallFirstTime(false))
        }
        this._getSession().setMute(isVideoMuted, TYPE_VIDEO).then(devices => {
            if (!isVideoMuted && this.isInProcessRecording()) {
                self.stopLocalRecording().then(() => {
                    setTimeout(function () {
                        self.startLocalRecording()
                    }, 1000);
                });
            }
            this.setMuteDeviceForSetting(isVideoMuted, TYPE_VIDEO);
        });
    };

    switchCamera = async(options) => {
        const {filter, setting} = store.getState()
        const {inProcess} = filter;
        const { isFilter, videoMuted } = setting;
        // options.options.mirror = options.cameraDeviceId != RioMeetJS.constants.CameraFacingMode.ENVIRONMENT
        RioLogger.log("switchCamera: ", inProcess);
        if (inProcess) {
            return Promise.resolve();
        }
        try {
            store.dispatch(setInProcess(true));
            if (isFilter && !videoMuted) {
                return await this.processFilter(options);
            }
        RioLogger.log('options--', options)
        return RioAppClient.switchCamera(options).then((localVideoTrack) => {
                RioLogger.log("switchCamera2: ", isFilter, videoMuted);
                
                this.setDeviceIdForSetting(options.cameraDeviceId, TYPE_VIDEO)
                this.resetLocalTracks(localVideoTrack, options.cameraDeviceId, TYPE_VIDEO);
                store.dispatch(setInProcess(false));
            });
        } catch (err) {
            //end try
            RioLogger.warn(`Error: switchCamera: ${err?.message}`);
            await this.reloadCamera(options.mirror);
        }
    };

    processFilter = async(options) => {
        const { filter, setting} = store.getState();
        const { isFilter } = setting;
        const { enableFilter } = filter;
        const { cameraDeviceId, isSetting } = options;

        let onFilter = options?.isFilter;
        if (!onFilter) {
            onFilter = isFilter;
        }
        RioLogger.log('processFilter', options, isFilter, onFilter, enableFilter);
        if (isSetting) {
            if (onFilter && enableFilter) {
                setTimeout(() => {
                    FilterFace.setFilter(options)
                }, 100);
            } else {
                FilterFace.setOffFilter();
            }
        } else {
            await FilterFace.stopFilter();
            this.disposeLocalStream(TYPE_VIDEO);
            //this.stopVideoStream('localStream');
            
            const trackOpt = {
                mediaType: TYPE_VIDEO,
                deviceId: cameraDeviceId
            }

            return RioAppClient.disposeTrack(trackOpt).then(() => {
                //on change camera
                if (cameraDeviceId) {
                    store.dispatch(setCameraDevice({cameraDeviceId: cameraDeviceId}));
                    this.setDeviceIdForSetting(cameraDeviceId, TYPE_VIDEO);
                }
                
                if (onFilter && enableFilter) {
                    setTimeout(() => {
                        FilterFace.setFilter(options)
                    }, 100);
                } else {
                    FilterFace.setOffFilter();
                    setTimeout(() => {
                        this.reloadCamera();
                    }, 100);
                }
            });
        }
    };

    reloadCamera = async(mirror = true) => {
        const {filter, setting} = store.getState()
        const {inProcess} = filter;
        const { cameraDeviceId, videoMuted } = setting;

        RioLogger.log("switchCamera: ", inProcess);
        if (inProcess) {
            return Promise.resolve();
        }
        store.dispatch(setInProcess(true));

        const options = {
            cameraDeviceId: cameraDeviceId,
            options: {
                videoMuted: videoMuted,
                mirror
            }
        }

        return RioAppClient.switchCamera(options).then((localVideoTrack) => {
            RioLogger.log("switchCamera2: ", videoMuted, JSON.stringify(options));
            
            this.resetLocalTracks(localVideoTrack, options.cameraDeviceId, TYPE_VIDEO)
            store.dispatch(setInProcess(false));
        });
    };
    
    switchAudio = async (options) => {
        const state = store.getState();
        const {localAudioTrack} = state.audioInput;

        if(localAudioTrack) {
            await this.destroyLocalTrack(localAudioTrack);
        }
        return RioAppClient.switchInputAudio(options).then((localAudioTrack) => {
          //this.resetLocalTracks(localAudioTrack,options.micDeviceId, TYPE_AUDIO)
          this.setDeviceIdForSetting(options.micDeviceId, TYPE_AUDIO)
        })
    };

    resetLocalTracks(newTrack, deviceId, type) {
        if (!newTrack) {
            return false;
        }
        let tracks = store.getState().localStream.localTracks;
        if (tracks instanceof Array) {
            tracks.forEach((track, index) => {
                if (track.type && track.type === type) {
                    tracks[index] = newTrack
                }
            });
            
            if (!this.checkExistVideoOrAudioTrack(tracks, type)) {
                tracks.push(newTrack)
            }
            
            if (type === TYPE_AUDIO) {
                 store.dispatch(setAudioDevice({audioDeviceId: deviceId}));
                 this.setDeviceIdForSetting(deviceId, TYPE_AUDIO)
            }
            if (type === TYPE_VIDEO) {
                store.dispatch(setCameraDevice({cameraDeviceId: deviceId}));
                this.setDeviceIdForSetting(deviceId, TYPE_VIDEO)
            }
            store.dispatch(setLocalStream(tracks));
        }
    }

    checkExistVideoOrAudioTrack(tracks, type = TYPE_VIDEO) {
        let track = [];
        if (tracks instanceof Array) {
            track = tracks.filter((stream) => {
                return (stream.type && stream.type === type);
            });
        }
        return track.length
    }
    
    disposeStream = () => {
        try {
            const state = store.getState();
            const tracks = state.localStream.localTracks || [];
            const {localVideoTrack} = state.VideoInput;
            const {localAudioTrack} = state.audioInput;

            if (localVideoTrack) {
                localVideoTrack.dispose();
                store.dispatch(setCameraDevice({ localVideoTrack: ''}));
            }

            if (localAudioTrack) {
                localAudioTrack.dispose();
                store.dispatch(setAudioDevice({localAudioTrack: ''}));
            }

            tracks.forEach((track) => {
                if (track && !track.disposed) {
                    track.dispose();
                }
            });
            //clear localTrack
            store.dispatch(setLocalStream([]));
        } catch (err) {
            RioLogger.warn(`Error: disposeStream: ${err?.message}`);
        }
    };

    startLocalRecording(options = {format: 'webm', type: TYPE_VIDEO}) {
        const recordingOption = Object.assign({}, options, {
            autoUpload: true, // true: auto upload to server api
            interval: 10, // minute auto upload file, download file, max: 10 mitnute,
        });
        return RioAppClient.startRecording(recordingOption).then((res) => {
            store.dispatch(setRecordingInProcess(true))
        }).catch(err => {
            RioLogger.error(`Error: startLocalRecording: ${err?.message}`);
        }); 
    };

    stopLocalRecording() {
        if (RioAppClient) {
            return RioAppClient.stopRecording().then(() => {
                store.dispatch(setSnackbar(true, 'the file recording has been successfully uploaded'))
                store.dispatch(setRecordingInProcess(false))
            }).catch(err => {
                store.dispatch(setSnackbar(true, 'the file recording upload to S3 failed'))
                RioLogger.error(`Error: Failed to stop local recording: ${err?.message}`);
            });
        }
        return Promise.reject('Failed to stop local recording');
    };

    toggleDisableDevice = async (type, isMuted = undefined) => {
        if (type === TYPE_AUDIO) {
            const {localAudioTrack} = store.getState().audioInput;
            if (localAudioTrack && localAudioTrack.isAudioTrack()) {
                isMuted = isMuted === undefined ? Helper.isMuted(localAudioTrack) : isMuted;
                const mute = isMuted ? 'unmute' : 'mute';
                await localAudioTrack[mute]();
                store.dispatch(setAudioDevice({localAudioTrack}));
                this.setMuteDeviceForSetting(!isMuted, type)
                return !isMuted;
            }
        }
        if (type === TYPE_VIDEO) {
            const {localVideoTrack} = store.getState().VideoInput;
            if (localVideoTrack && localVideoTrack.isVideoTrack()) {
                isMuted = isMuted === undefined ? Helper.isMuted(localVideoTrack) : isMuted;
                const mute = isMuted ? 'unmute' : 'mute';
                await localVideoTrack[mute]();
                store.dispatch(setCameraDevice({localVideoTrack}));
                this.setMuteDeviceForSetting(!isMuted, type)
                if (!isMuted ) {
                    this.settingToggleFilter(false);
                }
                return !isMuted;
            }
        }
    }

    async destroyLocalTrack(localTrack) {
        if (typeof localTrack.dispose == "function" && !localTrack.disposed) {
            await localTrack.dispose();
        }
        const { stream } = localTrack;
        if (stream) {
            this.destroyStream(stream);
        }
    }

    destroyStream(mediaStream) {
        if (!mediaStream) {
            return;
        }
        mediaStream.getTracks().forEach(track => {
            if (track.stop) {
                track.stop();
            }
        });

        // leave stop for implementation still using it
        if (mediaStream.stop) {
            mediaStream.stop();
        }

        // The MediaStream implementation of the react-native-webrtc project has
        // an explicit release method that is to be invoked in order to release
        // used resources such as memory.
        if (mediaStream.release) {
            mediaStream.release();
        }
    }

    _getSession() {
        return store.getState().session;
    }

    createLocalVideoTrack(cameraDeviceId) {
        const {setting} = store.getState();
        // On Electron there is no permission prompt for granting permissions. That's why we don't need to
        // spend much time displaying the overlay screen. If GUM is not resolved within 15 seconds it will
        // probably never resolve.
        const timeout = browser.isElectron() ? 15000 : 60000;
        RioLogger.log(`createLocalVideoTrack cameraDeviceId ${cameraDeviceId} ${timeout}`);

        return RioMeetJS.createLocalTrack(TYPE_VIDEO, cameraDeviceId, timeout)
            .then((localVideoTrack) => {
                store.dispatch(setCameraDevice({cameraDeviceId, localVideoTrack}));
                this.toggleDisableDevice(TYPE_VIDEO, !setting.videoMuted)
            }).catch(err => {
                this.setMuteDeviceForSetting(true)
                RioLogger.warn(`Error: createLocalVideoTrack: ${err?.message}`);
            });
    }

    createLocalAudioTrack(audioDeviceId) {
        const {setting} = store.getState();
        const {disableAudio} = appConfig;
        if (disableAudio === true && this.isScreen(CALLING) ) {
            //ignore create audio track on iOS app
            return;
        }
        // On Electron there is no permission prompt for granting permissions. That's why we don't need to
        // spend much time displaying the overlay screen. If GUM is not resolved within 15 seconds it will
        // probably never resolve.
        const timeout = browser.isElectron() ? 15000 : 60000;

        RioLogger.log(`createLocalAudioTrack audioDeviceId ${audioDeviceId} ${timeout}`);
        return RioMeetJS.createLocalTrack(TYPE_AUDIO, audioDeviceId, timeout)
            .then((localAudioTrack) => {
                store.dispatch(setAudioDevice({audioDeviceId, localAudioTrack}));
                 this.toggleDisableDevice(TYPE_AUDIO, !setting.audioMuted)
            }).catch(err => {
                 this.setMuteDeviceForSetting(true, TYPE_AUDIO)
                RioLogger.warn(`Error: createLocalAudioTrack: ${err?.message}`);
            });
    }

    isInProcessRecording = () => {
        const {recording} = store.getState()
        return !!(recording && recording.inProcess);
    }
    
    onSubtitle = (userId, msg) => {
        const subTitle = document.getElementById('sub-title')
        
        clearTimeout(this.timeOutSubTitle)
        if(msg && msg.final){
            subTitle.style.visibility = "visible"
            subTitle.innerHTML = msg.final
            this.clearSubtitle(subTitle)
        }
    }
    
    clearSubtitle(subTitle){
        this.timeOutSubTitle = setTimeout(function () {
            subTitle.innerHTML = ''
            subTitle.style.visibility = "hidden"
        }, 3000)
    }
    
    toggleSubtitle = (params) => {
        return this._getSession().toggleSubtitle(params)
    }
    
    checkOutputAudio = (micDeviceId, voice = 0, timeout = 5) => {
        const params = {micDeviceId, voice, timeout}
        const rioApp = RioAppClient.getApp();
        if (rioApp) {
            rioApp.checkOutputAudio(params);
        }
    }

    setMuteDeviceForSetting(isMuted, type = TYPE_VIDEO) {
        if (type == TYPE_VIDEO) {
            store.dispatch(setIsMuteCamera(isMuted))
        }
        if (type == TYPE_AUDIO) {
            store.dispatch(setIsMuteAudio(isMuted))
        }
    }

    setDeviceIdForSetting(deviceId, type) {
        RioLogger.info(`[debug] setDeviceIdForSetting ${deviceId} ${type}`);

        if (type == TYPE_VIDEO) {
            store.dispatch(setCameraDeviceId(deviceId))
        }
        if (type == TYPE_AUDIO) {
            store.dispatch(setMicDeviceId(deviceId))
        }
    }
    
    setSpeakerForSetting(deviceId) {
       store.dispatch(setSpeakerDeviceId(deviceId))
    }

    setVoiceChangeForSetting(type) {
        store.dispatch(setVoice(type))
    }

    isScreen(screenName) {
        const path = window.location.pathname;
        return path.includes(`/${screenName}`);
    }

    initSettingInSettingScreen() {
        const self = this;
        const {setting} = store.getState();
        const {
            //userId,
            //language,
            cameraDeviceId,
            micDeviceId,
            voice,
            isFilter,
            audioMuted,
            videoMuted
        } = setting;

        RioLogger.info(`[debug] initSettingInSettingScreen`, setting);

        //always start camera
        this.setCameraDeviceIdUsing(cameraDeviceId);
        this.setAudioDeviceIdUsing(micDeviceId);

        if (isFilter && !videoMuted) {
            this.settingToggleFilter(isFilter)
        }

        if (voice) {
        }
    }
    
    initSettingInCallingScreen() {
        const {setting} = store.getState();
        const {
            userId,
            micDeviceId,
            voice,
            isFilter,
            videoMuted
        } = setting;

        RioLogger.log(`[debug] initSettingInCallingScreen`, setting);
        if (isFilter && !videoMuted) {
            this.settingToggleFilter(isFilter, {
                isSetting : false,
                idCanvas: 'deeparCanvas',
                idLocalStream: 'localStream',
            })
            .catch(err => {
                // Error handling
                RioLogger.warn(`Error: [STEP] initSettingInCallingScreen: ${err?.message}`);
            });
        }

        if (voice && voice !== 'none') {
            this.setVoiceChangeForCalling(voice)
        }
    }
    
    setVoiceChangeForCalling(options) {
        if (this.isScreen(CALLING) && RioAppClient) {
             RioAppClient.voiceChange(options);
        }
    }
    
    async settingToggleFilter(isFilter, options = {}) {
        store.dispatch(setEnableFilter(isFilter));
        store.dispatch(setInProcess(true));
        const defaultParam = {
            isGetSize: true,
            isSetting: true,
            timeout : 1000,
            isFilter: isFilter
        };

        const params = {...defaultParam, ...options};
        const {filter} = store.getState();
        const {enableFilter} = filter;
        RioLogger.log('settingToggleFilter', isFilter, enableFilter, params);

        return await this.processFilter(params)
            .catch(err => {
                // Error handling
                RioLogger.warn(`Error: [STEP] settingToggleFilter: ${err?.message}`);
            });
        //if (isFilter && enableFilter) {
        //    setTimeout(() => {
        //        FilterFace.setFilter(params)
        //    }, params.timeout)
        //} else {
        //    FilterFace.setOffFilter();
        //    setTimeout(() => {
        //        this.reloadCamera();
        //    }, 1000);
        //}
    }

    async setAudioDeviceIdUsing(audioDeviceId) {
        //if (!audioDeviceId) {
        //    this.setMuteDeviceForSetting(true, TYPE_AUDIO)
        //}
        const state = store.getState();
        const {localAudioTrack} = state.audioInput;

        if(localAudioTrack) {
            await this.destroyLocalTrack(localAudioTrack);
        }
        const {setting} = store.getState()
        const isSpeaker = !setting.speaker;

        const newAudioDeviceId = this._getOutDeviceId(audioDeviceId, isSpeaker);
        RioLogger.info(`[debug] setAudioDeviceIdUsing ${audioDeviceId} ${newAudioDeviceId}`);

        if ( this.isScreen(SETTING) ) {
            this.createLocalAudioTrack(newAudioDeviceId);
        } else {
            setAudioDevice({audioDeviceId: newAudioDeviceId})
        }
        this.setDeviceIdForSetting(newAudioDeviceId, TYPE_AUDIO);
    }

    async setCameraDeviceIdUsing(cameraDeviceId) {
        const state = store.getState();
        const { filter, setting} = store.getState();
        const { isFilter } = setting;
        const { enableFilter } = filter;

        if (!cameraDeviceId) {
           this.setMuteDeviceForSetting(true)
        }

        const {localVideoTrack} = state.VideoInput;
        if (enableFilter) {
            await FilterFace.stopFilter();
        }

        if(localVideoTrack) {
            await this.destroyLocalTrack(localVideoTrack);
        }

        return this.createLocalVideoTrack(cameraDeviceId).then( async()=>{
            this.setDeviceIdForSetting(cameraDeviceId, TYPE_VIDEO);
            
            if (isFilter && enableFilter) {
                await this.settingToggleFilter(isFilter, {
                    isSetting : true,
                });
            }
        });
    }
    
    togglePIP = (enabled) => {
        store.dispatch(setIsTurnOnPip(enabled))
        RioAppClient.togglePIP({enabled, isLocal: false});
    };

    switchToVideoCall(data) {
        const {setting} = store.getState();
        const videoOptions = Object.assign({},
            {
                video: true,
                options: {
                    videoMuted: false,
                    audioMuted: false,
                },
                deviceId: setting.cameraDeviceId,
                cameraDeviceId: setting.cameraDeviceId,
            });
        this.switchCamera(videoOptions)
    }

    isAudioCall(data) {
        const {acceptCallType, currentUserID, callType, from, to} = data;
        return (acceptCallType == RioMeetJS.constants.CallType.AUDIO && currentUserID == to)
            || (callType == RioMeetJS.constants.CallType.AUDIO && currentUserID == from);
    }
    
    switchSpeaker() {
        const {setting} = store.getState()
        const { audioDeviceId } = setting;
        const isSpeaker = !setting.speaker;

        const newAudioDeviceId = this._getOutDeviceId(audioDeviceId, isSpeaker);
        RioLogger.info(`[debug] switchSpeaker ${audioDeviceId} ${newAudioDeviceId} isSpeaker ${isSpeaker}`);
        this.setDeviceIdForSetting(newAudioDeviceId, TYPE_AUDIO);

        if ( typeof RioAppClient.toggleSpeaker == "function") {
            RioAppClient.toggleSpeaker(isSpeaker)
            this.setSpeakerForSetting(isSpeaker);
        }
    }

    disposeTrack = (track) => {
        try {
            RioLogger.log('disposeTrack track');
            if (track && typeof track.stopStream == "function") {
                track.stopStream();
            }
        } catch (err) {
            RioLogger.log('dispose track err', err);
        }
    };

    disposeLocalStream = (type) => {
        const state = store.getState();
        const localTracks = state.localStream.localTracks || [];
        if (type === TYPE_VIDEO || type === TYPE_AUDIO) {
            const tracks = localTracks.filter((track) => {
                return (track.type && track.type === type);
            });

            tracks.forEach((track) => {
                this.disposeTrack(track)
            });
        } else {
            localTracks.forEach((track) => {
                this.disposeTrack(track)
            });
        }
    };

    disposeLocalPreViewStreams = () => {
        const state = store.getState();
        const {localVideoTrack} = state.VideoInput || {};
        let {localAudioTrack} = state.audioInput || {};

        if (localVideoTrack) {
            this.disposeTrack(localVideoTrack)
        }

        if (localAudioTrack) {
            this.disposeTrack(localAudioTrack)
        }
    };

    resetStoreDefault = () => {
        store.dispatch(resetSession());
        store.dispatch(resetPreCall());
        store.dispatch(resetRemoteStream());
        store.dispatch(resetFilter());
    };
    removeAllTrackAndDevice = (parentId= 'root') => {
        try {
            const $parentId = document.getElementById(parentId);
            if ($parentId) {
                const $videoEles = $parentId.querySelectorAll("video")
                const $audioELes = $parentId.querySelectorAll("audio")
                if ($videoEles) {
                    $videoEles.forEach(video => {
                        video.pause();
                        video.srcObject = null;
                        video.remove();
                    });
                }
                if ($audioELes) {
                    $audioELes.forEach(audio => {
                        audio.pause();
                        audio.srcObject = null;
                        audio.remove();
                    });
                }
            }
        } catch (err) {
            RioLogger.log('removeAllTrackAndDevice err', err);
        }
    }

    removeAllTrackAndDevicePreview = () => {
        try {
            this.removeAllTrackAndDevice('videochat-streams-setting')
            FilterFace.destroyFilter();
            this.disposeLocalPreViewStreams();
        } catch (err) {
            RioLogger.log('removeAllTrackAndDevicePreview err', err);
        }
    }
    removeAllTrackAndDeviceInCalling = () => {
        try {
            this.removeAllTrackAndDevice('videochat-streams')
            FilterFace.destroyFilter();
            this.resetStoreDefault();
            this.disposeLocalStream()
        } catch (err) {
            RioLogger.log('removeAllTrackAndDeviceInCalling err', err);
        }
    }
    
    isVisibleCamera() {
        const {setting} = store.getState();
        let {callType, videoMuted} = setting
        if (callType && callType == 2) {
             return false
        }
        return !videoMuted
    }
    
    initStoreSetting(settings) {
        const {videoMuted, audioMuted, isFilter, speaker} = settings;
        let session = this._getSession();
        if (session) {
            const {localStreamId, remoteStreamId} = this.mediaParams;
            const videoOptions = Object.assign({},
            {
                speaker: speaker,
                isFilter: isFilter,
                videoMuted: !videoMuted,
                audioMuted: !audioMuted,
                localStreamId: localStreamId || '',
                remoteStreamId: remoteStreamId || '',
            });
            session.setSessionConfigs(videoOptions);
        }
        RioLogger.info(`[debug]: initStoreSetting`, JSON.stringify(settings));
        store.dispatch(setInitConfig(settings));
    }

    async requestPermission() {
        // check camera
        await navigator.mediaDevices.getUserMedia({video: true})
            .then((result) => {
                this.destroyStream(result)
                this.setMuteDeviceForSetting(false, TYPE_VIDEO)
            }).catch(err => {
                this.setMuteDeviceForSetting(true, TYPE_VIDEO)
                RioLogger.log('requestPermission err', err);
            });

        const {disableAudio} = appConfig;
        if (disableAudio !== true ) {
            // check Mic
            await navigator.mediaDevices.getUserMedia({
                audio: {
                    echoCancellation: false
                }
            }).then((result) => {
                this.destroyStream(result)
                this.setMuteDeviceForSetting(false, TYPE_AUDIO)
            }).catch(err => {
                this.setMuteDeviceForSetting(true, TYPE_AUDIO)
                RioLogger.log('requestPermission err', err);
            });
        }
    }

    _initDeviceList = () => {
        return new Promise(resolve => {
            getAvailableDevices()
            .then(devices => {
                RioLogger.info('debug: _initDeviceList', devices);
                this.data['devices'] = devices;
                resolve(devices);
            });
        });
    }

    _getOutDeviceId = (deviceId, isSpeaker) => {
      const { devices } = this.data;

      const audioDevices = devices.filter(device => device.kind === 'audioinput');
      const len = audioDevices.length;
      let newDeviceId = deviceId == "" ? "default" : deviceId;

      for (var i = 0; i < len; i++) {
        var label = audioDevices[i]?.label;
        if(isSpeaker && label.indexOf('Speakerphone') !== -1) {
            newDeviceId = audioDevices[i]?.deviceId;
        }
        if(!isSpeaker && (label.indexOf('Headset') !== -1 || label.indexOf('earpiece') !== -1) ) {
            newDeviceId = audioDevices[i]?.deviceId;
        }
      }

      return newDeviceId;
    }
}

export default new CallService();
