/*global RioAppClient */
/*eslint-disable no-unused-vars, no-var, max-len, no-undef */
/*eslint no-undef: "error"*/
import RioMeetJS from "../rio-meet/riomeetjs";

//create instance RioAppClient
if (typeof window.RioMeetJS === 'object') {
  RioMeetJS.initInstance();
}

export const RioHelper = window.RioHelper || {};
export const RioAppClient = window.RioAppClient || {};


/**
 * Queries for connected A/V input and output devices and updates the redux
 * state of known devices.
 *
 * @returns {Function}
 */
export function getAvailableDevices() {
    return new Promise(resolve => {
        const { mediaDevices } = RioMeetJS;

        if (mediaDevices.isDeviceListAvailable()
                && mediaDevices.isDeviceChangeAvailable()) {
            mediaDevices.enumerateDevices(devices => {

                resolve(devices);
            });
        } else {
            resolve([]);
        }
    });
}
