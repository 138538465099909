import {SET_VIDEO_QUALITY
  } from "../actions/videoQuality";

export default (state = null, action) => {
  switch (action.type) {
    case SET_VIDEO_QUALITY:
      const {level} = action;
      return {...state, level}
    default:
      return state
  }
}