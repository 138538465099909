/* eslint-disable */
import React, {Component} from 'react'
import {connect} from "react-redux";
import {RioAppClient} from "../appClient/rioappclient";
import {defaultSetting} from "../../config";
import CallService from "../../services/call-service";
import CamerasSetting from "../Modal-preCall/devices/camerasSetting";
import RecordPreview from "../record";
import Filter from "../filter/Filter";
import AudiosSetting from "../Modal-preCall/devices/audiosSetting";
import VoiceChange from "../voiceChange/VoiceChange";
import AudioInputPreview from "../Modal-preCall/AudioInputPreview";
import {TYPE_AUDIO, TYPE_VIDEO} from "../../helpers/constants";
import Helper from "../../helpers/helpers";
import VideoInputPreview from "../Modal-preCall/VideoInputPreview.js"
import Speaker from "../speaker/Speaker";

class Setting extends Component {
    constructor(props) {
        super(props)
        this.state = {
            callId: false,
            audioMute: undefined,
            videoMute: undefined,
        }
    }

    componentWillUnmount() {
        CallService.removeAllTrackAndDevicePreview();
    }

    changeFilter = (isFilter) => {
        CallService.settingToggleFilter(isFilter, {timeout: 150})
    }

    saveSetting = () => {
        const {voice}  = this.props.setting;
        const voiceValue = typeof voice === 'object' ? voice?.value : voice;
        const setting = {...defaultSetting, ...this.props.setting, "voice": voiceValue.toString()};

        RioAppClient.setSetting(setting);
    }

    toggleDisableCamera = async (e) => {
        e.preventDefault();
        let isMuted = await CallService.toggleDisableDevice(TYPE_VIDEO);
        this.setState({videoMute: isMuted})
    }

    toggleDisableAudio = async (e) => {
        e.preventDefault();
        let isMuted = await CallService.toggleDisableDevice(TYPE_AUDIO)
        this.setState({audioMute: isMuted})
    }

    _classVideoButton(localVideoTrack) {
        return this.checkVideo(localVideoTrack);
    }

    checkVideo(localVideoTrack) {
        const {videoMute} = this.state;
        return videoMute === undefined ? Helper.isMuted(localVideoTrack) : videoMute;
    }

    isMute(localAudioTrack) {
        const {audioMute} = this.state;
        return audioMute === undefined ? Helper.isMuted(localAudioTrack) : audioMute;
    }

    leftBox = () => {
        const {videoInput, audioInput, filter} = this.props;
        const {localVideoTrack} = videoInput;
        const {localAudioTrack} = audioInput;
        const isCameraDisable = this._classVideoButton(localVideoTrack);
        const isAudioDisable = this.isMute(localAudioTrack);
        const {videoMute} = this.state;
        const classVideoPreview = videoMute ? 'video-setting' : 'video-setting off-minor ';
        const classVideoPreviewCanvas = filter && filter.isFiltered ? 'video-setting off-minor' : 'video-setting off-minor video-filter-canvas-preview';
        return <div className="left_box" id="videochat-streams-setting">
            <canvas className={classVideoPreviewCanvas} id="canvasPreview"></canvas>
            <VideoInputPreview
                className={filter && filter.isFiltered ? 'height-width-0' : classVideoPreview}
                playsinline={true}
                id="localVideoPreview"
                videoTrack={{rioTrack: localVideoTrack}}/>
            <div className="setting_front">
                <div
                    className={isAudioDisable ? "btn_action btn_mic active" : "btn_action btn_mic"}
                    onClick={this.toggleDisableAudio}
                >
                    <i className="icon_mic"></i>
                </div>
                <div
                    className={isCameraDisable ? "btn_action btn_video active" : "btn_action btn_video"}
                    onClick={this.toggleDisableCamera}
                >
                    <i className="icon_video"></i>
                </div>
            </div>

        </div>
    }

    rightBox = () => {
        const {videoInput, audioInput, filter} = this.props;
        const {localVideoTrack} = videoInput;
        const {localAudioTrack} = audioInput;
        const isCameraDisable = this._classVideoButton(localVideoTrack);
        const isAudioDisable = this.isMute(localAudioTrack);
        return (<div className="right_box">
            <h3>ビデオ管理</h3>
            <CamerasSetting isCameraDisable={isCameraDisable}/>
            <Filter changeFilter={this.changeFilter}/>
            <Speaker/>
            <h3>オーディオ管理</h3>
            <AudiosSetting isAudioDisable={isAudioDisable}/>
            <VoiceChange isAudioDisable={isAudioDisable}/>
            <div className="line_form"><label>マイク</label>
                <AudioInputPreview track={localAudioTrack}/>
            </div>
            <div className="line_form">
                {<RecordPreview isDisable={this.isMute(localAudioTrack)}/>}
            </div>
            <button className="button_form" onClick={() => this.saveSetting()}>保存</button>
        </div>)
    }

    render() {
        const {videoInput, audioInput, preCall} = this.props
        return (
            <div className="box_video">
                {videoInput && audioInput && preCall && this.leftBox()}
                {videoInput && audioInput && preCall && this.rightBox()}
            </div>
        )
    }
}

function mapStateToProps(state) {
    const {
        audioInput,
        snackbar,
        session,
        currentUser,
        setting,
        filter
    } = state
    const videoInput = state.VideoInput
    const preCall = state.PreCall

    return {
        preCall,
        videoInput,
        audioInput,
        snackbar,
        session,
        currentUser,
        setting,
        filter
    }
}

export default connect(mapStateToProps)(Setting);
