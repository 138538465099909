import React, {Component} from "react";
import {connect} from "react-redux";
import CallService from "../../services/call-service";

class Subtitle extends Component {

    defaultState  = {
        audio: true,
        subtitle : false
    };

    constructor(props) {
        super(props);
        this.state = this.defaultState;
    }
    
    toggleSubtitle = (enable) => {
        const params = {enable}
        CallService.toggleSubtitle(params).then(()=>{
            this.setState({subtitle : !this.state.subtitle})
        }).catch((error) => {
            console.error(error)
        });
    }

    render() {
        const classSubtitle = this.state.subtitle ? "btn_action btn_cc active" : "btn_action btn_cc";
        const toggleSubtitle = this.state.subtitle ? 0 : 1;
        return (
            <div 
                className={classSubtitle}
                onClick={() => this.toggleSubtitle(toggleSubtitle)}>
                <i className="icon_cc"></i>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
    }
}

export default connect(mapStateToProps)(Subtitle);
