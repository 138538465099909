import {
  SET_SESSION,
  RESET_SESSION
} from '../actions/session'

export default (session = null, action) => {
  switch (action.type) {
    case SET_SESSION:
      return action.session;

    case RESET_SESSION:
        return null;

    default:
      return session;
  }
}