/* eslint-disable */
import React, {Component} from 'react'
import {connect} from "react-redux";
import {setVoiceChange} from "../../actions/voiceChange";
import store from "../../store";
import RioMeetJS from "../rio-meet/riomeetjs";
import CallService from "../../services/call-service";
import {RioAppClient} from "../appClient/rioappclient";

class VoiceChange extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    voiceChange(options) {
        const {session} = this.props;
        const {localTracks} = store.getState().localStream;
        CallService.setVoiceChangeForSetting(options)
        if (session) {
            session.voiceChange(options, localTracks)
        } else {
            store.dispatch(setVoiceChange(options))
        }
    };

    setReverbType = async (event) => {
        const target = event.target;
        const name =  target.value;
        const {VoiceType} = RioMeetJS.constants
        const type =  VoiceType[name];
        if (type === '' || type === undefined){
            return false;
        }
        this.voiceChange(type)
    }

    getCurrentVoice = () =>{
        const {VoiceType} = RioMeetJS.constants
        const {setting} = this.props
        const voiceValue = typeof setting.voice === 'object' ? setting.voice?.value : setting.voice;


        return  Object.keys(VoiceType).find(key => VoiceType[key].value == voiceValue);
    }

    render() {
        const {VoiceType} = RioMeetJS.constants
        const keyVoice =  this.getCurrentVoice();
        let types = [];
        for (const [key, item] of Object.entries(VoiceType)) {
            types.push( <option key={key} value={key}>{item.name}</option>)
        }

        return (
             <div className="line_form">
                <label>ボイスチェンジャー</label>
                <select className="form_setting"
                    name="voiceChange"
                    id="voiceChange"
                    onChange={(e) =>this.setReverbType(e)}
                    defaultValue={keyVoice}
                >
                    {types}
                </select>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        loader: state.Loader,
        session: state.session,
        voiceChange: state.voiceChange,
        setting : state.setting
    };
}

export default connect(mapStateToProps)(VoiceChange);
