import {
    RESET_ALL,
    SET_VOICE_CHANGE
} from "../actions/voiceChange";

export default (state = {}, action) => {
    switch (action.type) {
        case SET_VOICE_CHANGE:
            const {value} = action;
            return { ...state, value}
        case RESET_ALL:
            return {}
        default:
            return state
    }
}
