import {
  FETCH_USERS,
} from '../actions/users'

export default (store = {}, action) => {
  switch (action.type) {
    case FETCH_USERS: {
      return {...store, ...action.users}
    }
    default: {
      return store
    }
  }
}
