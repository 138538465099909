import {
  IS_SHOW_PRECALL,
  RESET_PRECALL,
  SHOW_MODAL_PREVIEW,
  SET_CALLER_ID
} from '../actions/preCall'

export default (state = {}, action) => {
  switch (action.type) {
    case IS_SHOW_PRECALL:
      state.isShowPrecall = action.isShowPrecall;
      return {...state}
    case RESET_PRECALL:
      return {};
    case SHOW_MODAL_PREVIEW:
      state.isShowPreview = action.isShowPreview;
      return {...state}
    case SET_CALLER_ID:
      const {callId} = action;
      return {...state, callId}
    default:
      return state
  }
}