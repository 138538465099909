import {
    IS_DISABLE_PIP,
    IS_TURN_ON_PIP
} from '../actions/pip'

export default (state = {}, action) => {
    switch (action.type) {
        case IS_TURN_ON_PIP:
            const {enable} = action;
            return {...state, enable}
        case IS_DISABLE_PIP:
            const {isDisable} = action;
            return {...state, isDisable}
        default:
            return state
    }
}
