import {
    SET_ENABLE_FILTER,
    RESET_FILTER,
    SET_REMOTE_ENABLE_FILTER, 
    IS_FILTERED, 
    SET_IN_PROCESS
} from '../actions/filter'

export default (filter = {}, action) => {
    switch (action.type) {
        case SET_ENABLE_FILTER:
            filter.enableFilter = action.value;
            return {...filter}
        case SET_REMOTE_ENABLE_FILTER:
            filter.enableRemoteFilter = action.value;
            return {...filter}
        case RESET_FILTER:
            return {};
        case IS_FILTERED:
              filter.isFiltered = action.isFiltered;
            return {...filter}
         case SET_IN_PROCESS:
          const {inProcess} = action;
        return {...filter, inProcess}
        default:
            return filter
    }
}
