/* eslint-disable */ 
import React, {Component} from "react";
import {connect} from "react-redux";
import CallService from "../../services/call-service";
import store from "../../store";
import {setIntervalCountTime, setNumberTimeRecord} from "../../actions/recording";

class LocalRecording extends Component {

    defaultState  = {
        stopRecord : false,
        timeRecord : null,
        isDisabled : false
    };

    constructor(props) {
        super(props);
        this.state = this.defaultState;
        this.stopLocalRecording = this.stopLocalRecording.bind(this);
        this.startLocalRecording = this.startLocalRecording.bind(this);
    }

    stopLocalRecording = () => {
        this.setState({isDisabled:true})
        const {recording} = this.props;
        CallService.stopLocalRecording().then(() =>{
               this.setState({isDisabled:false})
               if (recording && recording.interval) {
                   clearInterval(recording.interval);
                   store.dispatch(setNumberTimeRecord(''))
               }
        });
    };

    startLocalRecording = (options) => {
        CallService.startLocalRecording(options).then(() => {
            this.countTimeRecord();
        })
    };

    isInProcess = () => {
        return CallService.isInProcessRecording()
    };

    countTimeRecord() {
        const self = this;
        let startTime = 0;
        let countTimeInterval = setInterval(function () {
            const timeRecord = self.getDisplayTime(startTime++)
            store.dispatch(setNumberTimeRecord(timeRecord))
        }, 1000);
        store.dispatch(setIntervalCountTime(countTimeInterval))
    }

    getDisplayTime(times) {
        if (times < 60) {
            return this.getFormatNumber(times, true);
        }

        if (times < 3600) {
            let minutes = Math.floor(times / 60);
            const second = times % 60
            return `${this.getFormatNumber(minutes)}:${this.getFormatNumber(second)}`;
        }

        if (times >= 3600) {
            const hours = Math.floor(times / 3600);
            const hoursMod = times % 3600;
            const minutes = Math.floor(hoursMod / 60);
            const second = hoursMod % 60;
            return `${this.getFormatNumber(hours)}:${this.getFormatNumber(minutes)}:${this.getFormatNumber(second)}`;
        }
    }

    getFormatNumber(number, isSecond = false){
        if (isSecond) {
            return parseInt(number) >= 10 ? `00:${number}` : `00:0${number}`;
        }
        return parseInt(number) >= 10 ? `${number}` : `0${number}`; 
    }

    render() {
        return (
            <div>
                {!this.isInProcess() &&
                    <div 
                        className="btn_action btn_record"
                        onClick={() => this.startLocalRecording({type: 'video' ,format : 'mp3'})}
                    ><i className="icon_record"></i></div>
                }
                {this.isInProcess() &&
                    <div 
                        className="btn_action btn_record"
                        onClick={this.stopLocalRecording.bind(this)}
                    ><i className="icon_is_recording"></i></div>
                }
                
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        recording: state.recording,
    }
}

export default connect(mapStateToProps)(LocalRecording);
