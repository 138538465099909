import { combineReducers } from 'redux'
import currentUser from './currentUser'
import users from './users'
import modalIcoming from "./modalIcoming";
import VideoInput from "./videoInput";
import PreCall from "./preCall";
import Snackbar from "./snackbar";
import Loader from "./loader";
import session from "./session";
import audioInput from "./audioInput";
import audioOutput from "./audioOutput";
import remoteStream from "./remoteStream";
import videoQuality from "./videoQuality";
import recording from "./recording";
import localStream from "./localStream";
import voiceChange from "./voiceChange";
import filter from "./filter";
import setting from "./setting";
import settingInCalling from "./settingInCalling";
import pip from "./pip";

const appReducer = combineReducers({
  currentUser,
  users,
  modalIcoming,
  VideoInput,
  PreCall,
  Snackbar,
  Loader,
  session,
  audioInput,
  audioOutput,
  remoteStream,
  videoQuality,
  recording,
  localStream,
  voiceChange,
  filter,
  setting,
  settingInCalling,
  pip
})


export const LogOut = () => ({ type: 'RESSET_STORE' })

const rootReducer = (state, action) => {
  if (action.type === 'RESSET_STORE') {

    state = {}
  }
  return appReducer(state, action)
}

export default rootReducer
