/* eslint-disable */ 
import React, {Component} from 'react'
import CallService from "../../services/call-service";
import {connect} from "react-redux";
import {QUALITIES_VIDEO} from "../../helpers/constants"
//import VoiceChange from "../voiceChange/VoiceChange";
import Filter from "../filter/Filter";
import {RioAppClient} from '../appClient/rioappclient';
import Audios from "../Modal-preCall/devices/audios";
import PIPMobile from "../PiP/PIPMobile";
import Helpers from "../../helpers/helpers";

class SettingInCalling extends Component {

    changeFilter = (isFilter) => {
        CallService.settingToggleFilter(isFilter, {
            isSetting : false,
            idCanvas: 'deeparCanvas',
            idLocalStream: 'localStream',
            timeout: 1000
        })
    }

    changeQualityVideo = (e) => {
        const type = e.target.value;
        if (type) {
            // eslint-disable-next-line no-undef
            return RioAppClient.changeVideoQuality(type);
        }
        throw new Error('type quality video invalid');
    }

    componentDidMount() {
        window.addEventListener('leavepictureinpicture', this.leavePIP);
    }


    leavePIP() {
        CallService.togglePIP(false)
    }
    
    render() {
        let level = 'medium';
        const {videoQuality, session, filter} = this.props;
        if (videoQuality) {
            level = videoQuality.level || level ;
        }
        return (
            <div  id="slide-out-panel" className="slide-out-panel">
                <header></header>
                <section>
                    <div className="line_form">
                        <label>ビデオ画質</label>
                        <select
                            className="form_setting"
                            aria-label="Default select"
                            onChange={(e) =>this.changeQualityVideo(e)}
                            defaultValue={level}
                        >
                            {QUALITIES_VIDEO.map((item, key) =>  (
                                <option key={key} value={item.value}>{item.label}</option>
                            ))}
                        </select>
                    </div>
                    <Audios/>
                    <Filter changeFilter={this.changeFilter}/>
                    {/*<VoiceChange/>*/}
                    {Helpers.isIOS() && <PIPMobile/>}
                </section>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        recording: state.recording,
        session: state.session,
        videoQuality: state.videoQuality,
        filter: state.filter,
    };
}

export default connect(mapStateToProps)(SettingInCalling);
