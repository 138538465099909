import {
  SET_VIDEO_INPUT,
  RESET_VIDEO_INPUT,
  SET_CAMERA_DEVICE
} from '../actions/videoInput'

export default (videoInput = null, action) => {
  switch (action.type) {
    case SET_VIDEO_INPUT:
      const {videos} = action
      return {...videoInput, videos}
    case RESET_VIDEO_INPUT:
      return null
    case SET_CAMERA_DEVICE:
      const {localVideoTrack} = action;
      return {...videoInput, ...localVideoTrack}
    default:
      return videoInput
  }
}