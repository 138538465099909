/* eslint-disable */ 
import React, { Component } from 'react';
import { Provider } from 'react-redux';
import '../assets/styles/index.scss';
import {
  BrowserRouter,
  Route,
} from "react-router-dom"
import store from '../store'
import Setting from "./setting/index";
import Calling from "./call/call";
import WaitingCall from "./waitingCall/index";
import UI from "../services/ui-service";
import CallService from "../services/call-service";
import FilterFace from "../services/filterFace";

export default class Main extends Component {
  constructor(props) {
    super(props)
    this.domLoaded = this.domLoaded.bind(this);
  }

  componentDidMount() {
    document.addEventListener('DOMContentLoaded', this.domLoaded);
    
      window.addEventListener("beforeunload", (ev) => {
            console.log('[debug] beforeunload');
            CallService.removeAllTrackAndDevice();
            FilterFace.destroyFilter();
            CallService.disposeLocalPreViewStreams();
            CallService.disposeLocalStream();
             delay(600);

            function delay(ms) {
                let start = +new Date;
                while ((+new Date - start) < ms);
            }
            return;
        });
  }

  componentWillUnmount() { 
    document.removeEventListener('DOMContentLoaded', this.domLoaded)  
  }

  domLoaded() {
    UI.init();
  }

  render() {
    return (
      <BrowserRouter basename={process.env.REACT_APP_BASE_URL}>
        <Provider store={store}>
              <Route path="/setting" component={Setting} />
              <Route path="/calling" component={Calling} />
              <Route path="/waiting" component={WaitingCall} />
        </Provider>
      </BrowserRouter>
    )
  }
}
