import React, {Component} from 'react'
import { connect } from "react-redux";
import CallService from "../../../services/call-service";
import { TYPE_VIDEO } from "../../../helpers/constants";

class CamerasSetting extends Component {

    constructor(props) {
        super(props)
        this.state = {
        }
    }

    async componentDidMount() {
        const {videos, setting} = this.props;
        const {cameraDeviceId,} = setting
        if (videos.length && !cameraDeviceId ){
            const defaultCameraDevice = videos[0]
            CallService.setDeviceIdForSetting(defaultCameraDevice.deviceId, TYPE_VIDEO);
        }
    }

    async onchangeCameraDevice(event) {
        const cameraDeviceId =  event.target.value;
        CallService.setCameraDeviceIdUsing(cameraDeviceId);
    }

    render() {
        const {videos, setting, isCameraDisable} = this.props;
        const {cameraDeviceId} = setting
        return (
            <div className="line_form">
                <label>カメラ</label>
                {videos &&
                    <select 
                        disabled={isCameraDisable} 
                        className="form_setting" 
                        onChange={(e) => this.onchangeCameraDevice(e)} 
                        defaultValue={cameraDeviceId}>
                            {videos.map((item, index) => (
                                <option  key={index} value={item.deviceId}>{item.label}</option>
                            ))}
                    </select>
                }
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        videos: state.VideoInput && state.VideoInput.videos,
        setting: state.setting
    }
}

export default connect(mapStateToProps)(CamerasSetting);
